<template>
    <Dialog
        id="print"
        :visible.sync="showCheckForm"
        position="center"
        :modal="true"
        :closeOnEscape="true"
        :closable="true"
        @update:visible="closeDialog"
    >
        <template #header>
            <div class="p-d-flex p-flex-column">
                <h3 class="p-mb-2" style="min-width: 600px;">Ошибки. {{ formTitle }}</h3>
            </div>
        </template>
        <div v-if="status.length !== 0">
            <div class="p-d-flex p-ai-center status">
                Задание: <span class="status-label calculated">{{ status }}</span>
            </div>
            <div class="p-d-flex p-flex-column p-mt-2 errorPos"
                 style="max-width: 1200px;"
            >
                Вы можете закрыть окно и продолжить работу.<br/><br/>
                По завершению проверки Вам прийдёт уведомление.
            </div>
        </div>
        <div class="p-d-flex p-flex-column p-col-12 p-lg-12 p-mb-lg-0 p-px-0 p-py-0 errorPos"
             style="max-width: 1200px; min-height: 400px"
        >
            <DataTable
                v-if="errors && errors.length > 0"
                :value="errors"
                stripedRows
                class="p-datatable-striped role-list"
                rowGroupMode="subheader"
                groupRowsBy="worksheet.attributes.name"
                responsiveLayout="scroll"
                sortMode="single"
                sortField="worksheet.attributes.name"
                :sortOrder="1"
                :scrollable="true"
                :paginator="false"
                :rows="200"
                showGridlines
                currentPageReportTemplate=""
            >
                <template #header>
                </template>
                <Column field="worksheet.attributes.name" />
                <Column field="formula">
                    <template #body="{ data }">
                        <div class="p-d-flex">
                            <div class="p-flex-column p-px-2" :style="data.explain ? 'width: 50%;' : ''">
                                <span class="error-description green" v-html="data.cell"></span>
                                <span>{{ data.message }}</span><br>
                                <span class="error-description" v-html="data.formula"></span>
                            </div>
                            <div v-if="data.explain" class="p-flex-column p-px-2" style="width: 50%;">
                                <span class="error-expain">{{ data.explain }}</span>
                            </div>
                        </div>
                    </template>
                </Column>
                <template #groupheader="slotProps">
                    <span class="worksheet-name">{{ slotProps.data.worksheet.attributes.name }}</span>
                </template>
                <template #paginatorLeft>
                    <div class="p-col-6"></div>
                </template>
            </DataTable>
        </div>
        <template #footer>
            <div class="p-d-flex p-jc-start p-py-4">
                <Button v-show="errors && errors.length > 0" class="p-button" type="button" @click="printErrors">
                    <span class="p-button-label">Распечатать</span><span class="p-ink"></span>
                </Button>
            </div>
        </template>
    </Dialog>
</template>

<script>

export default {
    name: 'checkFormDialog',

    props: {
        showWorksheet: {
            require: true,
            type: Boolean
        },

        showForm: {
            require: true,
            type: Boolean
        },

        formTitle: {
            require: true,
            type: String
        },

        errorList: {
            type: Array
        },

        errorStatus: {
            type: String
        },
    },

    emits: [ 'loadingChange', 'closeCheckDialog' ],

    data() {
        return {
            showCheckForm: this.showForm,
            cellData: null,
            cellIncluded: null,
            errorMeta: 0,
            errors: this.errorList,
            status: this.errorStatus
        };
    },

    methods: {
        closeDialog() {
            this.showCheckForm = false;
            this.$emit('closeCheckDialog');
        },

        printErrors() {
            const prtHtml = document.getElementById('print').innerHTML;
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=1,status=0');

            WinPrint.document.write(`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .p-flex-column {
                flex-direction: column !important;
            }

            .p-d-flex {
                display: flex !important;
            }

            .p-dialog-footer {
                display: none;
            }

            .p-dialog-header-icons {
                display: none;
            }
            .customStyles {
                width: 100%;
                max-height: 100%;
                overflow: auto;
                z-index: 10;
            }

            .userContainer {
                align-items: start;
                background: #fff;
                padding: 1rem;
            }

            .greyBg {
                background: #f5f5f5;
            }

            .error-description {
                font-weight: 500;
                margin: 4px 0;
            }

            .p-card {
                box-shadow: none;
            }

            .p-card .p-card-body {
                border-radius: 0;
                padding: 0 !important;
                border: none;
            }

            .p-card .p-card-content {
                padding: 0 !important;
                font-size: 13px;
            }

            .cell-marker {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #495058;
                border-radius: 3px;
                cursor: pointer;
                display: inline-block !important;
                background: #FF8F8F;
                padding: 2px;
                margin: 1px;
            }

            .green .cell-marker {
                background: #C5E0C8;
            }

            .error-expain {
                color: #F44336;
            }

            .printHeader {
                display: none;
            }

            @media print {
                .printHeader {
                    display: block;
                }
            }
        </style>
    </head>
    <body>
        ${prtHtml}
    </body>
</html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        },
    },

    watch: {
        showForm: {
            handler(to) {
                if (to) {
                    this.showCheckForm = this.showForm;
                }
            }
        },
        errorStatus: {
            handler(to) {
                if (to) {
                    this.status = this.errorStatus;
                }
            }
        },
        errorList: {
            handler(to) {
                if (to) {
                    this.errors = this.errorList;
                    this.status = '';
                }
            }
        }
    }
}
</script>

<style scoped>
::v-deep .p-datatable-thead {
    display: none;
}

.status {
    color: #8794a3;
    white-space: nowrap;

    .status-label {
        background: #ffe6a5;
        padding: 6px;
        border-radius: 4px;
        color: #495058;
    }
}

::v-deep .p-rowgroup-header {
    background-color: #ccc !important;
    align-items: center;
    color: #272727 !important;
}

.worksheet-name {
    display: block;
    text-align: center;
}

.error-expain {
    color: #F44336;
}

.error-description {
    font-weight: 500;
    margin: 4px 0;
}

::v-deep .cell-marker {
    max-height: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px !important;
    color: #495058;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block !important;
    background: #FF8F8F;
    padding: 2px;
    margin: 1px;
}

::v-deep .green .cell-marker {
    background: #C5E0C8;
}

@media print {
    .p-button {
        display: none;
    }

    ::v-deep .p-datatable-scrollable-body {
        overflow-y: auto !important;
        height: 100%;
    }

    ::v-deep .p-datatable-striped .p-datatable-tbody > tr {
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }

    ::v-deep .p-datatable-striped .p-datatable-tbody {
        border-bottom: 1px solid #ccc;
    }

    ::v-deep .p-datatable-striped .p-datatable-tbody > tr > td {
        padding: 8px;
    }

    ::v-deep .p-dialog-header {
        color: black;
        font-size: 1rem;
        padding: 8px !important;
    }

    ::v-deep .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
        background-color: #eaeaea !important;
    }
}
</style>
