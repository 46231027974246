<template>

    <div class="p-d-flex p-flex-column p-jc-start minusMargin" style="height: calc(100vh - 148px);">
        <div class="p-d-flex p-jc-between minusMargin2" style="height: calc(100vh - 148px);">
            <div :class="[showRightMenu ? 'p-col-custom' : 'p-col-12']" class="p-d-flex p-col-customPad p-flex-column p-px-0 p-py-0">
                <div class="p-jc-between p-d-flex elementMargin">
                    <div class="p-mt-0 constTitle p-d-flex p-flex-column">
                        <div class="p-d-flex p-jc-between">
                            <!--a tabindex="0" class="menu-button" :href="aggregateId ? '/aggregate/list' : '/dataEntry/list'">
                                <i class="pi pi-chevron-left icon-size"></i>
                            </a-->
                            <div v-if="distributionId" style="margin-left: 8px;">
                                <h4>Заполнение</h4>
                            </div>
                            <div v-else style="margin-left: 8px;">
                                <h4>Свод</h4>
                            </div>
                        </div>
                    </div>
                    <div class="p-mt-1 p-flex" style="display: inline-flex;">
                        <div class="p-mt-1 p-mr-2 status" v-show="calculated">
                            <span class="status-label calculated">Расчёт свода...</span>
                        </div>
                        <!--div class="p-mt-1 p-mr-2 status" v-show="isCheckFormWorksheet">
                            Проверка формы: <span class="status-label calculated">{{ checkedFormStatus }}</span>
                        </div-->
                        <div class="p-mt-1 p-mr-2 status" v-show="isCheckWorksheet">
                            Проверка на ошибки: <span class="status-label calculated">{{ checkedStatus }}</span>
                        </div>
                        <div class="p-mt-1 p-mr-2 status" v-show="isRefreshWorksheet">
                            Актуализация: <span class="status-label calculated">{{ refreshStatus }}</span>
                        </div>
                        <div class="p-mt-1 p-mr-2 status" v-show="calculatedCells.length > 0">
                            Расчёт: <span class="status-label calculated">{{ allCalculatedCells }} из {{ calculatedCells.length }}</span>
                        </div>
                        <div class="p-mt-1 status" v-show="distributionId || formDistributionId">
                            Статус: <span class="status-label" :style="`background: ${ formStatus.bgColor }; color: ${ formStatus.textColor };`">{{ formStatus.label }}</span>
                        </div>
                    </div>
                </div>
                <div class="p-jc-between p-d-flex">
                    <div class="p-mt-0 constTitle p-d-flex p-flex-column">
                        <div v-if="distributionId" class="p-d-flex" style="margin-left: 8px; flex-wrap: wrap;">
                            <div class="title-button">
                                <i class="pi icon custom-form"></i>
                                <span>{{ formTitle }}</span>
                            </div>
                            <div v-if="organization" class="title-button">
                                <i class="pi icon custom-icon-organization"></i>
                                <span style="white-space: normal;">{{ organization.attributes.shortName }}</span>
                            </div>
                            <div v-if="formPeriod" class="title-button">
                                <i class="pi icon custom-icon-calendar"></i>
                                <span>{{ formPeriod.attributes.name }}</span>
                            </div>
                            <div v-if="currentGroup" class="title-button">
                                <i class="pi icon custom-form-group"></i>
                                <span>Группа {{ currentGroup.header }}</span>
                            </div>
                            <div class="title-button">
                                <i class="pi pi-fw pi-file"></i>
                                <span>Лист {{ currentWorksheetTitle }}</span>
                            </div>
                        </div>
                        <div v-else class="p-d-flex" style="margin-left: 8px; flex-wrap: wrap;">
                            <div class="title-button">
                                <i class="pi icon custom-form"></i>
                                <span>{{ formTitle }}</span>
                            </div>
                            <div v-if="organizationsCount" class="title-button">
                                <i class="pi icon custom-icon-organization"></i>
                                <span style="white-space: normal;">{{ organizationsCount }}</span>
                            </div>
                            <div v-if="organization && 2 > organizationsCount" class="title-button">
                                <i class="pi icon custom-icon-organization"></i>
                                <span style="white-space: normal;">{{ organization.attributes.shortName }}</span>
                            </div>
                            <div v-if="periodsCount" class="title-button">
                                <i class="pi icon custom-icon-calendar"></i>
                                <span>{{ periodsCount }}</span>
                            </div>
                            <div v-if="period && 2 > periodsCount" class="title-button">
                                <i class="pi icon custom-icon-calendar"></i>
                                <span>{{ period.attributes.name }}</span>
                            </div>
                            <div v-if="currentGroup" class="title-button">
                                <i class="pi icon custom-form-group"></i>
                                <span>Группа {{ currentGroup.header }}</span>
                            </div>
                            <div class="title-button">
                                <i class="pi pi-fw pi-file"></i>
                                <span>Лист {{ currentWorksheetTitle }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollPanel v-if="notifications" style="max-height: 100px; margin-bottom: 8px; overflow: hidden;" class="custom-bar">
                    <Message v-for="message of notifications" severity="warn" :key="message.id">
                        <div v-html="message.attributes.text" />
                    </Message>
                </ScrollPanel>
                <Menubar class="constructor-bar" :model="topBarMenuItemsComputed">
                    <template #start>
                        <checkFormDialog
                            :formTitle="formTitle"
                            :showWorksheet="showWorksheet"
                            :showForm="showCheckDialog"
                            :errorList="errorList"
                            :errorStatus="checkedFormStatus"
                            @closeCheckDialog="closeCheckDialog"
                            ref="errorList"
                        />
                        <Button type="button"
                                class="saveButtonStyles p-mr-2"
                                v-show="showWorksheet"
                                :disabled="disabledCheck"
                                @click="showCheckForm"
                        >
                            <img alt="logo" src="@/assets/img/customIcons/magic-wand.svg" style="width: 14px; height: 14px; margin-right: 6px" />
                            <span class="p-button-label green">Проверить форму {{ timerCheckForm }}</span>
                        </Button>
                        <Button type="button"
                                class="p-button-yellow saveButtonStyles p-mr-2"
                                v-show="canReview"
                                @click="sendReview()"
                        >
                            <img alt="logo" src="@/assets/img/customIcons/check-circle.svg" style="width: 14px; height: 14px; margin-right: 6px" />
                            <span class="p-button-label">Отправить на проверку</span>
                        </Button>
                        <Button type="button"
                                class="saveButtonStyles p-mr-2"
                                v-show="canAccept && showWorksheet"
                                @click="showAcceptForm()"
                        >
                            <img alt="logo" src="@/assets/img/customIcons/check.svg" style="width: 14px; height: 14px; margin-right: 6px;" />
                            <span class="p-button-label green">Принять</span>
                        </Button>
                        <Button type="button"
                                class="p-button-danger saveButtonStyles red p-mr-2"
                                v-show="canDecline && showWorksheet"
                                @click="showAcceptForm()"
                        >
                            <img alt="logo" src="@/assets/img/customIcons/times-circle.svg" style="width: 14px; height: 14px; margin-right: 6px;" />
                            <span class="p-button-label">Отклонить</span>
                        </Button>
                        <Button type="button"
                                class="saveButtonStyles p-mr-2"
                                v-show="showWorksheet && formStatus.value === 'accepted' && canApprove"
                                @click="approveForm()"
                        >
                            <img alt="logo" src="@/assets/img/customIcons/check.svg" style="width: 14px; height: 14px; margin-right: 6px;" />
                            <span class="p-button-label green">Утвердить</span>
                        </Button>
                        <Button type="button"
                                class="p-button-danger saveButtonStyles red p-mr-2"
                                v-show="showWorksheet && formStatus.value === 'approved' && canApprove"
                                @click="revokeApproval()"
                        >
                            <img alt="logo" src="@/assets/img/customIcons/times-circle.svg" style="width: 14px; height: 14px; margin-right: 6px;" />
                            <span class="p-button-label">Отменить утверждение</span>
                        </Button>
                    </template>
                    <template #end>
                        <SelectButton v-model="selectedPanel"
                                      :options="aggregateId ? ( formDistributionId ? mainAggregateRightPanels : aggregateRightPanels) : rightPanels"
                                      optionLabel="panel"
                        >
                            <template #option="slotProps">
                                <div class="menu-button">
                                    <i :class="slotProps.option.icon"></i>
                                    <span class="p-button-label">{{ slotProps.option.label }}</span>
                                </div>
                            </template>
                        </SelectButton>
                    </template>
                </Menubar>
                <TabView v-if="aggregateId && 1 < activeTabIds.length" class="fill-tabs" scrollable @tab-change="changeSliceTabs">
                    <TabPanel v-for="(tab, index) in tabs"
                              :active.sync="activeTabIds[index]"
                              :key="index">
                        <template #header >
                            <span class="fill-tabs-title">{{ tab.label }}</span>
                        </template>
                    </TabPanel>
                </TabView>
                <div v-if="aggregateId && 0 < activeTab" class="p-py-2 p-new-btn p-d-flex p-ai-center p-jc-end" style="margin-top: -28px;">
                    <Button @click="downloadSlice" class="p-button">Скачать срез</Button>
                </div>
                <div class="p-pt-0 p-d-flex p-ai-center elementMargin" :class="{ hideElem: !showWorksheet }">
                    <span v-show="readingMode" class="customRedAlert">
                        Включен режим чтения <i class="pi pi-times-circle" @click="endReadingMode"></i>
                    </span>
                    <span v-show="readingMode" class="customRedCircle p-ml-2">
                        <i class="pi pi-question-circle" v-tooltip.top="'Загружены данные из истории заполнения, чтобы восстановить версию, нажмите Восстановить'"></i>
                    </span>
                </div>
                <div class="card elementMargin">
                    <div :class="{ hideElem: !showWorksheet }">
                        <hot-table :settings="hotSettings"
                                   :language="language"
                                   ref="myTable"
                        />
                    </div>
                    <div :class="{ hideElem: !showSlice }">
                        <hot-table :settings="sliceSettings"
                                   :language="language"
                                   ref="sliceTable"
                        />
                    </div>
                    <div class="card p-pt-3 p-pl-3" :class="{ hideElem: showWorksheet }">
                        <p>Загрузка...</p>
                    </div>
                </div>
            </div>

            <keep-alive>
                <rightPanel
                    v-if="selectedPanel"
                    @hideRightPanel="hideRightPanel"
                    @changeComponent="changeComponent"
                    :selectedPanel="selectedPanel"
                    :distributionId="distributionId"
                    :aggregateId="aggregateId"
                    :formDistributionId="formDistributionId"
                    :worksheetId="currentWorksheetId"
                    :groups="groups"
                    :worksheetHistory="worksheetHistory"
                    :worksheetErrors="worksheetErrors"
                    :errorGroups="errorGroups"
                    :errorFormStatus="checkedFormStatus"
                    :errorStatus="checkedStatus"
                    :worksheetComments="worksheetComments"
                    :formTitle="formTitle"
                    :worksheetTitle="currentWorksheetTitle"
                    :canEdit="canEdit"
                    :status="(currentGroup && currentGroup.status) || formStatus.value"
                    :canReport="canReport"
                    @listSelect="listSelect"
                    @showErrorCell="showErrorCell"
                    @showHistoryVersion="showHistoryVersion"
                    @restore="restore"
                    @download="downloadHistoryVersion"
                    @showCheckForm="showCheckForm"
                    :class="[showRightMenu ? 'menuShow' : '']"
                />
            </keep-alive>
            <acceptFormDialog
                :showAcceptDialog="showAcceptFormDialog"
                :title="formTitle"
                :worksheets="reviewWorksheets"
                :acceptedWorksheetIds="acceptedWorksheetIds"
                :declinedWorksheetIds="declinedWorksheetIds"
                :acceptedGroups="acceptedGroupIds"
                :declinedGroups="declinedGroupIds"
                :comments="worksheetStatusComments"
                @acceptForm="acceptForm"
                @closeAcceptFormDialog="closeAcceptFormDialog"
            />
            <loadDataDialog
                :distributionId="distributionId"
                :showLoadDataDialog="showLoadDataDialog"
                @uploadData="uploadData"
            />
            <validationErrorDialog
                :showValidationErrorDialog="showValidationErrorDialog"
                :validationError="validationError"
                @continueFilling="continueFilling"
            />
            <editedErrorDialog
                :showEditedErrorDialog="showEditedErrorDialog"
                :editedError="editedError"
                @acceptValue="acceptValue"
            />
        </div>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import {
    getTemplateData,
    getWorkSheetCells,
    getAggregateResults,
    getVisibilityRules,
    updateAggregateResults,
} from '@/api/form/formsTemplates';
import {
    getDistributionData,
    getDistributionWorksheetsStatuses,
    getDistributionResults,
    saveDistributionResults,
    getWorksheetComments,
    sendDistributionReview,
    sendUnloadingData,
    aggregateDistribution,
    getDistributionVersions,
    submitDistributionReview,
    approveDistributionReview,
    revokeDistributionApproval,
    restoreDistribution,
    resetDistribution,
    getDistributionCheck,
    downloadDistributionReport,
    uploadData
} from '@/api/form/formsDistributions';
import {
    sendAggregateUnloadingData,
    getAggregateData,
    createSlice,
    downloadSlice,
    getAggregateCheck,
    downloadAggregateReport
} from '@/api/form/formsAggregate';
import { getFormGroupPermissions } from '@/api/form';
import { getReportTemplates } from '@/api/form/reportTemplates';
import { HotTable } from '@handsontable/vue';
import mockDataTableDataArray from '@/utils/mockDataTableDataArray';
import { v4 as uuidv4 } from 'uuid';
import rightPanel from '@/components/dataEntry/rightPanel.vue';
import { DISTRIBUTION_STATUSES, DISTRIBUTION_VALUE_TYPES_NAMES, XSL_TEMPLATE_ID, XSLA_TEMPLATE_ID } from '@/constants/distributions';
import { DEFAULT_PAGE_SIZE, FORM_PERMISSIONS_MAP, USER_PERMISSIONS_MAP } from '@/constants/common';
import { mapGetters } from 'vuex';
import { socketUrl } from '@/api/api.js';
import checkFormDialog from '@/components/dataEntry/dialogs/checkFormDialog.vue';
import loadDataDialog from '@/components/dataEntry/dialogs/loadDataDialog.vue';
import validationErrorDialog from '@/components/dataEntry/dialogs/validationErrorDialog';
import editedErrorDialog from '@/components/dataEntry/dialogs/editedErrorDialog';
import acceptFormDialog from '@/components/dataEntry/dialogs/acceptFormDialog';
import { jsonApiListParser } from '@/main/utils/common';
import { getNotificationByDistribution } from '@/api/notifications';
import {
    COLOR_PALETTE,
    CHECK_STATUSES
} from '@/constants/forms';

const {
    read,
    write,
    report,
    review,
    approve
} = FORM_PERMISSIONS_MAP

const {
    formApprove,
    formReadOwn,
    formReview,
    formVerificationRules,
    formWrite
} = USER_PERMISSIONS_MAP

/**
 * @desc Компонент детальной страницы заполнения/свода
 */
export default {
    name: 'fill',

    mixins: [requestToastHandler],

    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },

    emits: ['loadingChange'],

    components: {
        editedErrorDialog,
        validationErrorDialog,
        rightPanel,
        HotTable,
        checkFormDialog,
        loadDataDialog,
        acceptFormDialog
    },

    data() {
        this.formStatuses = DISTRIBUTION_STATUSES;
        this.valueTypes  = DISTRIBUTION_VALUE_TYPES_NAMES;
        this.colorPalette = COLOR_PALETTE;

        return {
            notifications: null,
            tabs: [{ label: 'Свод' }],
            messages: [],
            activeTabIds: [ true ],
            activeTab: 0,
            form: null,
            reportTemplates: null,
            templateData: null,
            templateIncluded: null,
            editedCell: null,
            editedError: null,
            showEditedErrorDialog: false,
            cellData: null,
            cellIncluded: null,
            distributionTemplateId: null,
            formTitle: '',
            organizationsCount: '',
            periodsCount: '',
            period: '',
            templateYear: null,
            formStatus: {},
            disabledCalc: process.env.VUE_APP_AUTOCALC === 'false',
            startTimerCalc: false,
            timerCalc: '',
            startTimerError: false,
            timerError: '',
            worksheetStatuses: [],
            acceptDistributions: [],
            emptyStatuses: [],
            formPeriod: null,
            organization: '',
            formDistributionPeriods: null,
            formDistributionOrganizations: null,
            groups: null,
            worksheets: null,
            errorList: [],
            currentWorksheetTitle: '',
            currentWorksheetId: null,
            currentGroup: null,
            timerCheckForm: '',
            startTimerCheckForm: false,
            disabledCheck: process.env.VUE_APP_CHECK_FORM === 'false',
            showCheckDialog: false,
            showWorksheet: false,
            showSlice: false,
            readingMode: false,
            previousValue: null,
            dataFromTable: [],
            transformData: [],
            mergeCellsArray: [],
            hiddenRows: [],
            hiddenColumns: [],
            blockedRows: [],
            blockedColumns: [],
            coloredRows: [],
            coloredColumns: [],
            dataFromSlice: [],
            transformSliceData: [],
            mergeCellsData: [],
            mergeSliceCellsArray: [],

            typeLayers: null,
            hintLayers: null,
            worksheetHistory: [],
            worksheetErrors: null,
            errorGroups: [],
            worksheetComments: [],

            formDistributionCheckData: null,
            checkAggregate: {},

            loadErrorsState: false,


            results: [],
            resultsIncluded: [],
            rightPanels: [
                { label: 'Комментарии', icon: 'icon custom-comment', code: 'comments' },
                { label: 'Ошибки листа', icon: 'pi pi-exclamation-triangle', code: 'mistakes' },
                { label: 'Ошибки формы', icon: 'pi pi-exclamation-circle', code: 'formMistakes' },
                { label: 'История', icon: 'icon custom-history-icon', code: 'history' },
                { label: 'Листы', icon: 'icon custom-list-icon', code: 'lists' },
            ],
            aggregateRightPanels: [
                { label: 'Листы', icon: 'icon custom-list-icon', code: 'lists' },
                { label: 'Ошибки листа', icon: 'pi pi-exclamation-triangle', code: 'mistakes' },
                { label: 'Ошибки формы', icon: 'pi pi-exclamation-circle', code: 'formMistakes' },
            ],
            mainAggregateRightPanels: [
                { label: 'Комментарии', icon: 'icon custom-comment', code: 'comments' },
                { label: 'Ошибки листа', icon: 'pi pi-exclamation-triangle', code: 'mistakes' },
                { label: 'Ошибки формы', icon: 'pi pi-exclamation-circle', code: 'formMistakes' },
                { label: 'Листы', icon: 'icon custom-list-icon', code: 'lists' },
            ],
            showRightMenu: false,
            selectedPanel: null,
            calculated: false,
            checked: false,
            isCheckWorksheet: false,
            isCheckFormWorksheet: false,
            isRefreshWorksheet: false,
            checkedFormStatus: '',
            checkedStatus: '',
            refreshStatus: '',
            calculatedCells: [],
            errorCells: [],
            allCalculatedCells: 0,
            coordinates: {
                columnLabels: [],
                rowLabels: []
            },
            colWidths: [],
            rowHeights: [],
            headerFixed: false,
            unlockHeader: false,
            sliceSettings: {
                manualColumnFreeze: true,
                data: [],
                colHeaders: true,
                rowHeaders: true,
                fillHandle: {
                    autoInsertRow: false,
                },
                autoColumnSizeObject: false,
                autoRowSizeObject: false,
                autoRowSize: false,
                width: '100%',
                mergeCells: true,
                selectionMode: 'single',
                viewportRowRenderingOffset: 10,
                viewportColumnRenderingOffset: 10,
                rowHeights(index) {
                    return index === 0 ? 30 : 30;
                },
                colWidths(index) {
                    return index === 0 ? 200 : 150;
                },
                licenseKey: 'non-commercial-and-evaluation',
                afterRenderer: (td, row, col, prop, value, cellProperties) => {
                    if (this.dataFromSlice[row][col] !== undefined) {
                        cellProperties.head = this.dataFromSlice[row][col].head;
                        cellProperties.readOnly = this.dataFromSlice[row][col].readOnly;
                    } else {
                        cellProperties.head = false;
                        cellProperties.readOnly = true;
                    }
                },
            },

            hotSettings: {
                autoColumnSizeObject: false,
                autoColumnSize: false,
                autoRowSize: { syncLimit: '40%', allowSampleDuplicates: true },
                manualColumnResize: true,
                manualRowResize: true,
                data: [],
                colHeaders: true,
                rowHeaders: true,
                fillHandle: {
                    autoInsertRow: false,
                },
                contextMenuMode: 'cell',
                readOnlyCellClassName: 'header',
                contextMenu: this.contextMenuSettings,
                dropdownMenu: this.dropdownMenuSettings,
                width: '100%',
                mergeCells: true,
                selectionMode: 'single',
                viewportRowRenderingOffset: 10,
                viewportColumnRenderingOffset: 10,
                minSpareCols: 0,
                minSpareRows: 0,
                enterMoves: { row: 0, col: 0 },
                hiddenColumns: false,
                hiddenRows: false,
                comments: true,
                rowHeights(index) {
                    return index === 0 ? 30 : 36;
                },
                colWidths(index) {
                    return index === 0 ? 200 : 150;
                },
                licenseKey: 'non-commercial-and-evaluation',
                afterRenderer: (td, row, col, prop, value, cellProperties) => {
                    if (this.dataFromTable[row]) {
                        if (this.dataFromTable[row][col]) {
                            cellProperties.id = this.dataFromTable[row][col].id;
                            cellProperties.head = this.dataFromTable[row][col].head;
                        }
                        cellProperties.readOnly = this.dataFromTable[row][col] ? this.dataFromTable[row][col].readOnly : true;
                        cellProperties.className = this.dataFromTable[row][col] ? this.dataFromTable[row][col].className : '';
                        if (cellProperties.comment) {
                            cellProperties.comment = this.dataFromTable[row][col] ? this.dataFromTable[row][col].comment : null;
                        }
                    }
                },
                afterSelection: (row, column) => {
                    if (this.aggregateId) {


                        if (-1 === column) {
                            this.$refs.myTable && this.$refs.myTable.hotInstance.updateSettings({
                                contextMenu: this.rowContextMenuSettings,
                            });
                            this.contextMenuMode = 'column';
                        }
                        if (-1 === row) {
                            this.$refs.myTable && this.$refs.myTable.hotInstance.updateSettings({
                                contextMenu: this.dropdownMenuSettings,
                            });
                            this.contextMenuMode = 'row';
                        }
                        if (0 <= column && 0 <= row && 'cell' !== this.contextMenuMode) {
                            this.$refs.myTable && this.$refs.myTable.hotInstance.updateSettings({
                                contextMenu: this.contextMenuSettings,
                            });
                            this.contextMenuMode = 'cell';
                        }
                    }
                },

                afterChange: (changes) => {
                    if (!changes) {
                        return;
                    }

                    changes.forEach((change) => {
                        const [row, column, oldValue, newValue] = change;
                        this.previousValue = oldValue;

                        this.dataFromTable[row][column].layers[0].layersDescr = newValue;
                        if (row >= 0 && column >= 0 && !this.dataFromTable[row][column].readOnly) {
                            const cellId = this.dataFromTable[row][column].id;

                            if (cellId.length && cellId.length === 36 && oldValue !== newValue && !this.dataFromTable[row][column].mock) {
                                this.saveResult(row, column);
                            }
                        }
                    });
                },
            },
            language: 'en-US',
            acceptedWorksheetIds: [],
            declinedWorksheetIds: [],
            acceptedGroupIds: [],
            declinedGroupIds: [],
            showAcceptFormDialog: false,
            showLoadDataDialog: false,
            showValidationErrorDialog: false,
            validationError: {},
            worksheetStatusComments: [],
            canApprove: false,
            canAccept: false,
            canDecline: false,
            canReview: false,
            canEdit: true,
            canLoad: false,
            canReport: true,
            formGroupPermissions: [],
            showGroupIds: [],
            reviewGroupIds: [],
            reviewWorksheets: [],
            formDistributionInterval: null,
            contextMenuSettings: {
                items: {
                    colBehavior: {
                        name: "<i class='icon custom-slice'></i>Срез по колонке",
                        callback: (key, selection) => {
                            if (!this.headerFixed) {
                                this.$toast.add({
                                    severity: 'info',
                                    summary: 'Чтобы построить срез, зафиксируйте шапку в шаблоне.',
                                    life: '3500',
                                });
                                return;
                            }
                            if (selection[0].start.col + 1 <= this.coordinates.fixedColumn) {
                                this.$toast.add({
                                    severity: 'error',
                                    summary: 'Невозможно построить срез по колонке: выбраны ячейки шапки, в которых нет значений.',
                                    life: '3500',
                                });
                            } else {
                                if (0 < selection[0].start.col + 1) {
                                    this.createSlice('column', selection[0].start.col + 1);
                                }
                            }
                        },
                    },
                    rowBehavior: {
                        name: "<i class='icon custom-slice'></i>Срез по строке",
                        callback: (key, selection) => {
                            if (!this.headerFixed) {
                                this.$toast.add({
                                    severity: 'info',
                                    summary: 'Чтобы построить срез, зафиксируйте шапку в шаблоне.',
                                    life: '3500',
                                });
                                return;
                            }
                            if (selection[0].start.row + 1 <= this.coordinates.fixedRow) {
                                this.$toast.add({
                                    severity: 'error',
                                    summary: 'Невозможно построить срез по строке: выбраны ячейки шапки, в которых нет значений.',
                                    life: '3500',
                                });
                            } else {
                                if (0 < selection[0].start.row + 1) {
                                    this.createSlice('row', selection[0].start.row + 1);
                                }
                            }
                        },
                    }
                }
            },
            dropdownMenuSettings: {
                items: {
                    colBehavior: {
                        name: "<i class='icon custom-slice'></i>Срез по колонке",
                        callback: (key, selection) => {
                            if (0 < selection[0].start.col + 1) {
                                this.createSlice('column', selection[0].start.col + 1);
                            }
                        },
                    }
                }
            },
            rowContextMenuSettings: {
                items: {
                    rowBehavior: {
                        name: "<i class='icon custom-slice'></i>Срез по строке",
                        callback: (key, selection) => {
                            if (0 < selection[0].start.row + 1) {
                                this.createSlice('row', selection[0].start.row + 1);
                            }
                        },
                    }
                }
            },


        };
    },

    methods: {
        showArrayMap() {
            let rowsMap = [];

            this.dataFromTable.forEach((row, rowIndex) => {
                row.forEach((cell) => {
                    rowsMap[rowIndex] = rowsMap[rowIndex] ? rowsMap[rowIndex] : [];
                    rowsMap[rowIndex].push(`${cell.attributes.row} ${cell.attributes.column}`);
                });
            });
        },

        async getDistributionData() {
            try {
                this.$emit('loadingChange', true);

                // eslint-disable-next-line no-unused-vars
                const { data, included } = await getDistributionData(this.distributionId);
                this.distributionTemplateId = included.find((item) => item.type === 'form-template').id;
                this.formPeriod = included.find((item) => item.type === 'form-distribution-period');
                this.organization = included.filter((item) => item.type === 'organization').slice(-1)[0];
                this.formStatus = this.formStatuses.find((item) => item.value === data.attributes.status);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async getData() {
            this.$emit('loadingChange', true);

            try {
                this.distributionTemplateId ? await Promise.all([
                    getDistributionWorksheetsStatuses(this.distributionId).then((statuses) => {
                        this.worksheetStatuses = statuses;
                    }),
                    getTemplateData(this.distributionTemplateId).then(({ data, included }) => {
                        this.templateData = data;
                        this.templateIncluded = included;
                    })
                ]) : '';
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },

        async getAggregateData() {
            this.$emit('loadingChange', true);

            try {
                await Promise.all([
                    getAggregateData(this.aggregateId).then(({ data, included }) => {
                        if (data.attributes.organizationsCount > 1) {
                            this.organizationsCount = `${ data.attributes.organizationsCount } организации(й)`;
                        }
                        if (data.attributes.periodsCount > 1) {
                            this.periodsCount = `${ data.attributes.periodsCount } периода(ов)`;
                        }
                        if (included) {
                            this.period = included.find((item) => item.type === 'form-distribution-period');
                        }
                        this.emptyStatuses = data.meta.worksheets;
                        this.acceptDistributions = data.relationships?.distributions?.data;
                    }),
                    this.formDistributionId ? getDistributionWorksheetsStatuses(this.formDistributionId).then((statuses) => {
                        this.worksheetStatuses = statuses;
                    }) : '',
                    this.formDistributionId ? getDistributionData(this.formDistributionId).then(({ data, included }) => {
                        this.formStatus = this.formStatuses.find((item) => item.value === data.attributes.status);
                        this.organization = included.filter((item) => item.type === 'organization').slice(-1)[0];
                        this.period = included.find((item) => item.type === 'form-distribution-period');
                    }) : '',
                    getTemplateData(this.distributionTemplateId).then(({ data, included }) => {
                        this.templateData = data;
                        this.templateIncluded = included;
                    }),
                ]);
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },

        async getTemplateData() {
            try {
                this.$emit('loadingChange', true);

                if (!this.distributionTemplateId && this.templateId) {
                    this.distributionTemplateId = this.templateId;
                }

                if (this.distributionId) {
                    await this.getData();
                } else {
                    await this.getAggregateData();
                }

                this.templateYear = this.templateData.attributes.year;
                this.form = this.templateIncluded.find(i => i.type === 'form');
                if (this.distributionId) {
                    const { data } = await getNotificationByDistribution(this.form.id);
                    if (data) {
                        this.notifications = data.data.map(item => {
                            item.attributes.text = item.attributes.text.replace(/\n/g, '<br/>');
                            return item;
                        });
                    }
                }
                this.worksheets = this.templateIncluded.filter((item) => item.type === 'form-template-worksheet');

                const intervals = this.templateIncluded.filter((item) => item.type === 'form-distribution-interval')[0];
                this.formDistributionInterval = intervals.id;

                this.formTitle = `${ this.form.attributes.name } (${ this.templateYear })`;

                if (this.templateIncluded) {
                    const {
                        data: permissionData,
                        included: permissionIncluded
                    } = await getFormGroupPermissions(this.currentUser.id, `formId[]=${ this.form.id }`);

                    if (permissionData) {
                        let formGroupPermissions = jsonApiListParser(permissionData, permissionIncluded);
                        formGroupPermissions.forEach(({ group, operation }) => {
                            if (!this.showGroupIds.includes(group.id) && ['read', 'write', 'review'].includes(operation)) {
                                this.showGroupIds.push(group.id);
                            }
                            if (operation === 'review' && !this.reviewGroupIds.includes(group.id)) {
                                this.reviewGroupIds.push(group.id);
                            }

                            if (!this.formGroupPermissions[group.id]) {
                                this.formGroupPermissions[group.id] = {
                                    'read': false,
                                    'write': false,
                                    'approve': false,
                                    'distribute': false,
                                    'report': false,
                                    'review': false,
                                };
                            }
                            this.formGroupPermissions[group.id][operation] = true;
                        });
                    }
                }

                this.templateIncluded
                    ? (this.groups = this.aggregateGroups())
                    : (this.groups = [
                          {
                              id: 'noId',
                              header: 'Листы без группы',
                              lists: [],
                              color: '',
                              sort: -1,
                              statusInfo: null
                          },
                      ]);

                if (this.groups && !this.currentGroup && this.formGroupPermissions.length > 0) {
                    this.currentGroup = this.groups.find(group => this.formGroupPermissions.keys().includes(group.id));
                }

                this.canAccept = this.$route.meta.review && this.formStatus.value !== 'accepted'
                    && [ formReview, formWrite ].some(p => this.userPermissionsObject[p]);
                this.canDecline = this.$route.meta.review
                    && [ formReview, formWrite ].some(p => this.userPermissionsObject[p])
                        ? !this.canAccept : false;

                this.canReview = this.formStatus.allowEdit
                    && this.distributionId && !this.organization.relationships.parent.data;

                this.canReport = [ formVerificationRules, formReadOwn ].some(p => this.userPermissionsObject[p])

                if (this.formPermissionsObject[this.form.id]
                    && !this.formPermissionsObject[this.form.id][write]
                    && this.formPermissionsObject[this.form.id][read]) {
                    this.canReview = false;
                    this.canEdit = false;
                }

                if (this.formPermissionsObject[this.form.id]
                    && this.formPermissionsObject[this.form.id][write]
                    && !this.formPermissionsObject[this.form.id][read]) {
                    this.canEdit = true;
                    this.canReview = this.formStatus.allowEdit && !this.organization.relationships.parent.data;
                }
                if (this.formPermissionsObject[this.form.id]
                    && !this.formPermissionsObject[this.form.id][report]) {
                    this.canReport = false;
                }
                if (this.formPermissionsObject[this.form.id]
                    && !this.formPermissionsObject[this.form.id][write]
                    && !this.formPermissionsObject[this.form.id][read]) {
                    this.canReview = false;
                    this.canEdit = false;
                }
                if (!this.distributionId && this.formPermissionsObject[this.form.id]
                    && this.formPermissionsObject[this.form.id][review]) {
                    this.canAccept = this.formStatus.value !== 'accepted';
                    this.canDecline =  this.formStatus.value === 'accepted';
                }
                if (this.formPermissionsObject[this.form.id]
                    && !this.formPermissionsObject[this.form.id][review]) {
                    this.canAccept = false;
                    this.canDecline = false;
                }
                if (!this.distributionId && this.formPermissionsObject[this.form.id]) {
                    this.canApprove = this.formPermissionsObject[this.form.id][approve] ? this.formStatus.value === 'accepted' : false;
                }
                if (Object.keys(this.formPermissionsObject).length > 0
                    && !this.formPermissionsObject[this.form.id]) {
                    this.canReview = false;
                    this.canReport = false;
                    this.canEdit = false;
                    this.canApprove = false;
                    this.canAccept = false;
                    this.canDecline = false;
                }

                if (this.currentGroup && this.formGroupPermissions[this.currentGroup.id]
                    && !this.formGroupPermissions[this.currentGroup.id]['read']
                    && !this.formGroupPermissions[this.currentGroup.id]['write']) {
                    this.canEdit = false;
                    this.canView = false;
                    this.canReview = false;
                }

                if (this.currentGroup && this.formGroupPermissions[this.currentGroup.id]
                    && this.formGroupPermissions[this.currentGroup.id]['write']
                    && !this.formGroupPermissions[this.currentGroup.id]['read']) {
                    this.canEdit = true;
                    this.canView = !this.canEdit;
                    this.canReview = [ 'new', 'in_work', 'declined' ].includes(this.currentGroup.status) && !this.organization.relationships.parent.data;
                }

                if (this.currentGroup && this.formGroupPermissions[this.currentGroup.id]
                    && !this.formGroupPermissions[this.currentGroup.id]['write']
                    && this.formGroupPermissions[this.currentGroup.id]['read']) {
                    this.canEdit = false;
                    this.canView = true;
                    this.canReview = false;
                }

                if (!this.distributionId && this.currentGroup && this.formGroupPermissions[this.currentGroup.id]) {
                    this.canApprove = this.formGroupPermissions[this.currentGroup.id]['approve'];
                }

                if (this.currentGroup && Object.keys(this.formGroupPermissions).length > 0
                    && !this.formGroupPermissions[this.currentGroup.id]) {
                    this.canEdit = false;
                    this.canView = false;
                    this.canReview = false;
                    this.canApprove = false;
                }

                this.canLoad = false;
                if (this.currentGroup) {
                    this.canLoad = ['new', 'in_work', 'declined'].includes(this.currentGroup.status);
                } else {
                    this.canLoad = this.formStatus.allowEdit;
                }

                if (this.worksheetId) {
                    await this.listSelect({ listId: this.worksheetId, listTitle: '' });
                } else {
                    /** Если id воркшита нет в url, по-умолчанию открываем первый лист раздачи */
                    if (!this.currentWorksheetId) {
                        const workSheets = this.templateIncluded.filter((include) => include.type === 'form-template-worksheet').sort((a, b) => (a.attributes.sort > b.attributes.sort ? 1 : -1));

                        await this.listSelect({ listId: workSheets[0].id, listTitle: workSheets[0].attributes.name });
                    }
                }

                this.$emit('loadingChange', false);
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },

        aggregateGroups() {
            let allWorksheets = [];
            let groups = [];

            this.templateIncluded.forEach((include) => {
                if (include.type === 'form-template-worksheet') {
                    let groupId = include.relationships.group.data?.id;

                    if (groupId) {
                        if (!allWorksheets[groupId]) {
                            allWorksheets[groupId] = [];
                        }

                        allWorksheets[groupId].push(include);
                    } else {
                        if (!allWorksheets['noGroup']) {
                            allWorksheets['noGroup'] = [];
                        }

                        allWorksheets['noGroup'].push(include);
                    }
                }
                if (include.type === 'form-group') {
                    groups.push(include);
                }
            });

            let currentGroupId;
            let allGroups = [];
            groups.forEach((group) => {
                if (allWorksheets[group.id]) {
                    group.statuses = allWorksheets[group.id].map((worksheet) => {
                        let status = 'new',
                            statusData = this.worksheetStatuses.find((status) => status.relationships.worksheet.data.id === worksheet.id);

                        if (statusData) {
                            status = statusData.attributes.status;
                        }
                        return status;
                    });

                    let statusIndex = null;
                    let statusIndexes = DISTRIBUTION_STATUSES.map((el) => {
                        return el.value;
                    });

                    group.statuses.map((status) => {
                        if (!status) {
                            return;
                        }

                        let findIndex = statusIndexes.indexOf(status);

                        if (
                            statusIndex === null ||
                            findIndex < statusIndex
                        ) {
                            statusIndex = findIndex;
                        }
                    });

                    if (this.showGroupIds.includes(group.id) || this.showGroupIds.length === 0) {
                        allGroups.push({
                            ...group.attributes,
                            id: group.id,
                            header: group.attributes.name,
                            status: statusIndexes[statusIndex],
                            lists: allWorksheets[group.id].map((worksheet) => {
                                if (this.worksheetId && worksheet.id === this.worksheetId) {
                                    currentGroupId = group.id;
                                }

                                let statusInfo = this.worksheetStatuses.find((status) => status.relationships.worksheet.data.id === worksheet.id);
                                let emptyInfo = this.emptyStatuses.find((status) => status.worksheetId === worksheet.id);

                                return {
                                    name: worksheet.attributes.name,
                                    code: worksheet.attributes.code,
                                    sort: worksheet.attributes.sort,
                                    empty: this.distributionId ? statusInfo?.attributes.empty : emptyInfo?.empty,
                                    id: worksheet.id,
                                    statusInfo: this.formStatuses.find((item) => item.value === statusInfo?.attributes.status)
                                };
                            }),
                            statusInfo: this.formStatuses.find((item) => item.value === statusIndexes[statusIndex]),
                        });
                    }
                }
            });

            if (this.worksheetId && currentGroupId) {
                this.currentGroup = allGroups.find((group) => group.id === currentGroupId);
            }

            if (allGroups.length === 0 && allWorksheets['noGroup']) {
                allGroups.unshift({
                    id: 'noId',
                    header: 'Листы без группы',
                    lists: allWorksheets['noGroup'].map((worksheet) => {
                        let statusInfo = this.worksheetStatuses.find((status) => status.relationships.worksheet.data.id === worksheet.id);

                        return {
                            name: worksheet.attributes.name,
                            code: worksheet.attributes.code,
                            sort: worksheet.attributes.sort,
                            empty: statusInfo?.attributes.empty,
                            statusInfo: this.formStatuses.find((item) => item.value === statusInfo?.attributes.status),
                            id: worksheet.id,
                        };
                    })
                        .sort((a, b) => (a.sort > b.sort ? 1 : -1)),
                    statusInfo: this.formStatuses[0],
                    sort: -1,
                });
            }

            return allGroups.sort((a, b) => (a.sort > b.sort ? 1 : -1));
        },

        showErrorCell({ row, column }) {
            this.$refs.myTable.hotInstance.selectCell(row, column);
        },

        async showHistoryVersion(version, readingMode = true) {
            try {
                this.$emit('loadingChange', true);
                this.showWorksheet = false;
                const { data: resultData, included: resultIncluded } = await getDistributionResults(this.distributionId, this.currentWorksheetId, { calc: 0, version: version });
                this.results = resultData;
                this.resultsIncluded = resultIncluded;

                this.showArrayMap();
                this.$emit('loadingChange', false);

                this.dataFromTable.forEach((row, rowIndex) => {
                    row.forEach((cell, cellIndex) => {
                        this.dataFromTable[rowIndex][cellIndex].readOnly = true;
                        if (this.dataFromTable[rowIndex][cellIndex].resultId) {
                            this.dataFromTable[rowIndex][cellIndex].layers[0].layersDescr = null;
                        }
                    });
                });
                setTimeout(() => {
                    this.$refs.myTable?.hotInstance?.updateSettings({});
                }, 50);

                this.updateData();
                this.showWorksheet = true;
                if (this.results !== []) {
                    await this.getResults(false);
                }
                this.readingMode = readingMode;
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },

        async restore(version) {
            try {
                this.$emit('loadingChange', true);

                const result = await restoreDistribution(this.distributionId, version);

                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }

                this.$toast.add({
                    severity: 'success',
                    summary: 'Версия из истории успешно восстановлена.',
                    life: '3200',
                });
                this.listSelect({ listId: this.currentWorksheetId, listTitle: '' });
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },

        async reset() {
            try {
                this.$emit('loadingChange', true);

                const result = await resetDistribution(this.distributionId);

                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }

                this.$toast.add({
                    severity: 'success',
                    summary: 'Очистка завершена успешно.',
                    life: '3200',
                });
                this.listSelect({ listId: this.currentWorksheetId, listTitle: '' });
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },

        async downloadHistoryVersion(version) {
            try {
                this.$emit('loadingChange', true);
                const result = await downloadDistributionReport(this.distributionId, XSL_TEMPLATE_ID, version);

                if (result.status === 204) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Запрос на скачивание версии из истории поставлен в очередь, перейдите в раздел выгрузок',
                        life: 2500,
                    });
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        endReadingMode() {
            this.readingMode = false;
            this.dataFromTable.forEach((row, rowIndex) => {
                row.forEach((cell, cellIndex) => {
                    if (cell.resultId) {
                        this.dataFromTable[rowIndex][cellIndex].readOnly = false;
                    }
                });
            });
            setTimeout(() => {
                this.$refs.myTable?.hotInstance?.updateSettings({});
            }, 50);
            this.listSelect({ listId: this.currentWorksheetId, listTitle: '' });
        },

        async listSelect({ listId, listTitle }) {
            this.$emit('loadingChange', true);
            this.mergeCellsArray = [];
            this.worksheetErrors = [];
            this.messages = [];
            this.errorCells = [];
            this.currentWorksheetTitle = '';
            this.showWorksheet = false;
            this.showSlice = false;
            this.allCalculatedCells = 0;
            this.activeTab = 0;
            for (let i = 0; i < this.tabs.length; i++) {
                this.activeTabIds[i] = false;
            }
            this.activeTabIds[0] = true;

            try {
                this.currentWorksheetId = listId;
                let worksheetInfo = this.worksheets.find((item) => item.id === this.currentWorksheetId);
                this.currentGroup = this.groups.find(item => item.id === worksheetInfo.relationships?.group.data?.id);

                if (!this.distributionId && this.currentGroup && this.formGroupPermissions[this.currentGroup.id]
                    && this.formGroupPermissions[this.currentGroup.id]['review']) {
                    this.canAccept = this.currentGroup.status !== 'accepted';
                    this.canDecline = this.formGroupPermissions[this.currentGroup.id]['review'] ? !this.canAccept : false;
                }

                if (!this.distributionId && this.currentGroup && this.formGroupPermissions[this.currentGroup.id]
                    && !this.formGroupPermissions[this.currentGroup.id]['review']) {
                    this.canAccept = false;
                    this.canDecline = false;
                }

                if (this.currentGroup && this.formGroupPermissions[this.currentGroup.id]) {
                    this.canEdit = this.formGroupPermissions[this.currentGroup.id]['write'];
                }

                if (!this.distributionId && this.currentGroup && this.formGroupPermissions[this.currentGroup.id]) {
                    this.canApprove = this.formGroupPermissions[this.currentGroup.id]['approve'];
                }

                let fixedRow = 0;
                let fixedColumn = 0;
                await Promise.all([
                    getWorkSheetCells(listId).then((data) => {
                        this.cellData = data.data;
                        this.cellIncluded = data.included;
                        let worksheet = this.cellIncluded.find((item) => item.type === 'form-template-worksheet');

                        if (worksheet.attributes.properties.fixedColumnsLeft > 0 || worksheet.attributes.properties.fixedRowsTop > 0) {
                            fixedRow = worksheet.attributes.properties.fixedRowsTop;
                            fixedColumn = worksheet.attributes.properties.fixedColumnsLeft;
                            this.coordinates = {
                                fixedRow: fixedRow,
                                fixedColumn: fixedColumn,
                                columnLabels: worksheet.attributes.properties.columnLabels || [],
                                rowLabels: worksheet.attributes.properties.rowLabels || []
                            };
                        }
                        this.colWidths = [];
                        this.rowHeights = [];
                        if (worksheet.attributes?.properties.columnWidths?.length > 0) {
                            this.colWidths = worksheet.attributes.properties.columnWidths;
                        }
                        if (worksheet.attributes?.properties.rowHeights?.length > 0) {
                            this.rowHeights = worksheet.attributes.properties.rowHeights;
                        }
                        /** В зависимости от статуса листа раздачи
                         *  Если статус листа не "новый" или "в работе" - блокировать редактирование
                         *  Если администратор или сотрудник ЦМС - не блокируем редактирование (право formWrite, formApprove, formReview, formVerificationRules)
                         *  Если есть право на редактирование напрямую - не блокируем редактирование
                         *  Если установлено правило видимости для сотрудника ЦМС (право formVerificationRules) - для других пользователей блокируем редактирование
                         **/
                        let blocked = !this.canEdit;

                        if (!this.currentUser.userPermissions.find((permission) => ['formWrite', 'formReview', 'formApprove', 'formVerificationRules'].includes(permission.id))) {
                            if (this.worksheetStatuses.length > 0 && this.canEdit) {
                                let worksheetStatus = this.worksheetStatuses.find((status) => status.relationships.worksheet.data.id === this.currentWorksheetId);

                                if (worksheetStatus) {
                                    worksheetStatus = worksheetStatus.attributes.status;
                                    blocked = !this.formStatuses.find((item) => item.value === worksheetStatus).allowEdit;
                                }
                            }
                        }

                        this.dataFromTable = this.aggregateCells(this.cellData, this.cellIncluded, blocked);
                    }),
                    this.distributionId ? getVisibilityRules(listId).then((visibilityRulesArray) => {
                        this.hiddenColumns = [];
                        this.hiddenRows = [];
                        this.blockedRows = [];
                        this.blockedColumns = [];
                        visibilityRulesArray.forEach((rule) => {
                            if (rule.relationships.period?.data?.id !== this.formPeriod.id && rule.attributes.rule === 'visible_during_period') {
                                if (rule.attributes.column > 0) {
                                    this.hiddenColumns.push(rule.attributes.column - 1);
                                }
                                if (rule.attributes.row > 0) {
                                    this.hiddenRows.push(rule.attributes.row - 1);
                                }
                            }

                            /** Для права formVerificationRules доступны для редактирования строки/колонки */
                            if (rule.attributes.rule === 'editable_by_verifier'
                                && !this.currentUser.userPermissions.find((permission) => ['formVerificationRules'].includes(permission.id))) {
                                if (rule.attributes.column > 0) {
                                    this.blockedColumns.push(rule.attributes.column - 1);
                                }
                                if (rule.attributes.row > 0) {
                                    this.blockedRows.push(rule.attributes.row - 1);
                                }
                            }
                        });
                    }) : '',
                    this.getFirstResults(listId),
                ]);
                this.dataFromTable.forEach((row) => {
                    row.forEach((cell) => {
                        if (this.blockedRows.includes(cell.attributes.row) || this.blockedColumns.includes(cell.attributes.column)) {
                            this.dataFromTable[cell.attributes.row][cell.attributes.column].readOnly = true;
                        }
                    });
                });

                if (this.colWidths?.length <= 0) {
                    for (let index = 0; index <= this.maxColNumber - 1; index++) {
                        if (!this.colWidths[index]) {
                            this.colWidths[index] = 150;
                        }
                    }
                }
                let hot = this.$refs.myTable?.hotInstance;
                let styleData = this.dataFromTable;
                let colorPalette = this.colorPalette;
                setTimeout(() => {
                    hot?.updateSettings({
                        colWidths: this.colWidths,
                        cells: function (row, col) {
                            let cell = null;
                            try {
                                cell = hot.getCell(row, col);
                            } catch(error) {
                                return;
                            }
                            if (styleData[row] && styleData[row][col]?.attributes.style?.length > 0 && cell?.style) {
                                styleData[row][col].attributes.style.forEach((styleParam) => {
                                    /** Задать полужирный стиль */
                                    if (styleParam === 'bold') {
                                        cell.style.fontWeight = styleParam;

                                        if (styleData[row][col].className.length > 0) {
                                            styleData[row][col].className = `${styleData[row][col].className} bold-style`;
                                        } else {
                                            styleData[row][col].className = 'bold-style';
                                        }
                                    }

                                    /** Задать Курсив */
                                    if (styleParam === 'italic') {
                                        cell.style.fontStyle = styleParam;

                                        if (styleData[row][col].className.length > 0) {
                                            styleData[row][col].className = `${styleData[row][col].className} italic-style`;
                                        } else {
                                            styleData[row][col].className = 'italic-style';
                                        }
                                    }

                                    /** Задать зачёркнутый стиль */
                                    if (styleParam === 'strikethrough') {
                                        cell.style.textDecoration = 'line-through';

                                        if (styleData[row][col].className.length > 0) {
                                            styleData[row][col].className = `${styleData[row][col].className} strikethrough-style`;
                                        } else {
                                            styleData[row][col].className = 'strikethrough-style';
                                        }
                                    }

                                    /** Задать подчёркнутый стиль */
                                    if (styleParam === 'underline') {
                                        cell.style.textDecoration = styleParam;

                                        if (styleData[row][col].className.length > 0) {
                                            styleData[row][col].className = `${styleData[row][col].className} underline-style`;
                                        } else {
                                            styleData[row][col].className = 'underline-style';
                                        }
                                    }

                                    /** Задать цвет фона ячейки */
                                    if (styleParam.includes('background:')) {
                                        let colorArray = styleParam.split('background:');
                                        let color = colorPalette.find((color) => color.code === colorArray[1]);
                                        if (styleData[row][col].readOnly) {
                                            if (styleData[row][col].className === 'blocked') {
                                                cell.style.backgroundColor = `#${color.calculated}`;
                                            } else {
                                                cell.style.backgroundColor = `#${color.blocked}`;
                                            }
                                        } else {
                                            cell.style.backgroundColor = `#${color.background}`;
                                        }

                                        if (styleData[row][col].className.length > 0) {
                                            styleData[row][col].className = `${styleData[row][col].className} ${color.code}-background-style`;
                                        } else {
                                            styleData[row][col].className = `${color.code}-background-style`;
                                        }
                                    }

                                    /** Задать цвет текста ячейки */
                                    if (styleParam.includes('color:')) {
                                        let colorArray = styleParam.split('color:');
                                        let color = colorPalette.find((color) => color.code === colorArray[1]);
                                        cell.style.color = `#${color.font}`;

                                        if (styleData[row][col].className.length > 0) {
                                            styleData[row][col].className = `${styleData[row][col].className} ${color.code}-style`;
                                        } else {
                                            styleData[row][col].className = `${color.code}-style`;
                                        }
                                    }

                                    /** Задать шрифт ячейки */
                                    if (styleParam.includes('font-size:')) {
                                        let sizeArray = styleParam.split('font-size:');
                                        cell.style.fontSize = sizeArray[1]

                                        if (styleData[row][col].className.length > 0) {
                                            styleData[row][col].className = `${styleData[row][col].className} style-${sizeArray[1]}`;
                                        } else {
                                            styleData[row][col].className = `style-${sizeArray[1]}`;
                                        }
                                    }
                                });
                            }
                        }
                    });
                }, 50);
                this.showArrayMap();
                if (!listTitle.length) {
                    listTitle = this.worksheets.find((worksheet) => worksheet.id === listId).attributes.name;
                }
                this.currentWorksheetTitle = listTitle;
                this.$emit('loadingChange', false);

                this.showWorksheet = true;
                setTimeout(() => {
                    this.distributionId && (this.hiddenRows.length > 0 || this.hiddenColumns.length > 0) ?
                        this.$refs.myTable?.hotInstance?.updateSettings({
                            language: 'en-US',
                            hiddenRows: {
                                rows: this.hiddenRows
                            },
                            hiddenColumns: {
                                columns: this.hiddenColumns
                            }
                    }) : this.$refs.myTable?.hotInstance?.updateSettings({
                        hiddenRows: false,
                        hiddenColumns: false
                    });
                }, 50);

                this.updateData();
                this.fixHeaders(!this.unlockHeader, fixedRow, fixedColumn);

                if (0 !== this.results.length) {
                    console.log('Получены результаты c calc=0: ', this.results);
                    console.log('Всего авторасчитываемых ячеек: ', this.calculatedCells);
                    await this.getResults();
                    console.log('Осталось получить ячейки: ', this.calculatedCells);
                } else {
                    if (this.aggregateId && 0 === this.calculatedCells.length) {
                        //console.log('Ждём рассчитываемые ячейки: ', this.calculatedCells.length);
                        this.calculated = true;
                        this.$emit('loadingChange', true);
                        const {
                            data: resultData,
                            included: resultIncluded
                        } = await getAggregateResults(this.aggregateId, listId, 'y', '');
                        this.results = resultData;
                        this.resultsIncluded = resultIncluded;

                        if (this.results !== []) {
                            await this.getResults();
                        }
                        this.calculated = false;
                        this.$emit('loadingChange', false);
                    }
                }



                if (this.calculatedCells.length > 0) {
                    console.log('Ждём рассчитываемые ячейки: ', this.calculatedCells.length);
                    let newResults, prevResults = 0;
                    do {
                        prevResults = newResults;
                        if (this.distributionId) {
                            const {
                                data: resultData,
                                included: resultIncluded
                            } = await getDistributionResults(this.distributionId, listId, {calc: 50});
                            this.results = resultData;
                            this.resultsIncluded = resultIncluded;
                        }

                        if (this.templateId) {
                            const {
                                data: resultData,
                                included: resultIncluded
                            } = await getAggregateResults(this.aggregateId, listId, 'y', '');
                            this.results = resultData;
                            this.resultsIncluded = resultIncluded;
                        }

                        newResults = this.results.length;
                        console.log('Получены результаты c calc=50: ', this.results);
                        if (this.results !== []) {
                            await this.getResults();
                            console.log('Осталось получить ячейки: ', this.calculatedCells)
                        } else {
                            console.log('Больше не запрашиваем!');
                            /** Если результаты не пришли - не запрашиваем заново */
                            this.calculatedCells = [];
                        }
                    } while (this.calculatedCells.length > 0 && prevResults !== newResults);
                }
                await Promise.all([
                    this.distributionId
                        ? getDistributionVersions(this.distributionId, listId).then(({ data: userData, included: versionIncluded }) => {
                            this.worksheetHistory = [];
                            if (userData.length) {
                                this.worksheetHistory = this.agregateHistory(userData, versionIncluded);
                            }
                        }) : '',
                    this.distributionId || this.formDistributionId
                        ? getWorksheetComments(this.distributionId || this.formDistributionId, listId).then(({ data: commentData, included: commentIncluded }) => {
                            this.worksheetComments = [];
                            if (commentData.length) {
                                this.worksheetComments = this.formatComments(commentData, commentIncluded);
                            }
                        }) : '',
                    getReportTemplates({
                        formId: this.form.id,
                        page: 1,
                        pageSize: DEFAULT_PAGE_SIZE,
                        include: '',
                        filter: {
                            year: this.templateYear,
                            active: true
                        }
                    }).then(({ data, included }) => {
                        this.reportTemplates = jsonApiListParser(data, included);
                    })
                ]);
            } catch (error) {
                this.$requestError(error.message);
                console.log(error);
            } finally {
                this.$emit('loadingChange');
            }
        },

        highlightErrors() {
            let hot = this.$refs.myTable?.hotInstance;
            if (this.errorCells.length > 0) {
                let errorCells = this.errorCells;
                setTimeout(() => {
                    hot?.updateSettings({
                        cells: function (row, col) {
                            let cell = null;
                            try {
                                cell = hot.getCell(row, col);
                            } catch (error) {
                                return;
                            }
                            let errorCell = errorCells?.find(cell => cell.row === row && cell.column === col);
                            if (errorCell && cell) {
                                cell.style.boxShadow = 'inset 0 0 0 1px rgb(255, 103, 103)';
                            }
                        }
                    });
                }, 1000);
            } else {
                setTimeout(() => {
                    hot?.updateSettings({
                        cells: function (row, col) {
                            let cell = null;
                            try {
                                cell = hot.getCell(row, col);
                            } catch (error) {
                                return;
                            }
                            if (cell) {
                                cell.style.boxShadow = 'none';
                            }
                        }
                    });
                }, 1000);
            }
        },

        async showCheckForm() {
            this.$root.reachGoal('check');
            await this.loadFormDistributionCheckData();
            this.showCheckDialog = true;
            if (Number(process.env.VUE_APP_CHECK_FORM_TIMEOUT) > 0) {
                this.startCheckTimer(Number(process.env.VUE_APP_CHECK_FORM_TIMEOUT));
            }
        },

        startCheckTimer(count) {
            if (this.startTimerCheckForm) {
                return;
            }

            let startTime = new Date();
            let stopTime = startTime.setMinutes(startTime.getMinutes() + count);

            let that = this;
            let countdown = setInterval(function() {
                let now = new Date().getTime();
                let remain = stopTime - now;

                let min = Math.floor( (remain % (1000 * 60 * 60)) / (1000 * 60) );
                let sec = Math.floor( (remain % (1000 * 60)) / 1000 );
                sec = sec < 10 ? '0' + sec : sec;

                that.timerCheckForm = '( ' + min + ':' + sec + ' )';

                if (remain < 0) {
                    clearInterval(countdown);
                    that.timerCheckForm = '';
                    that.startTimerCheckForm = false;
                    that.disabledCheck = false;
                }
            }, 1000);

            this.startTimerCheckForm = true;
            this.disabledCheck = true;
        },

        async loadCheckErrors() {
            this.distributionId
                ? await getDistributionCheck(this.distributionId, `&worksheetId[]=${ this.currentWorksheetId }`)
                : await getAggregateCheck(this.aggregateId, `&worksheetId[]=${ this.currentWorksheetId }`);
        },

        async checkErrors() {
            this.worksheetErrors = this.aggregateErrors(this.formDistributionCheckData.items.data, this.formDistributionCheckData.items.included ? this.formDistributionCheckData.items.included : [])
        },

        fixHeaders(payload, fixedRow = 0, fixedColumn = 0) {
            this.headerFixed = fixedRow > 0 || fixedColumn > 0;

            let settings = {
                fixedRowsTop: payload ? fixedRow : 0,
                fixedColumnsLeft: payload ? fixedColumn : 0,
                colHeaders: payload ? true : this.coordinates.columnLabels,
                rowHeaders: payload ? true : this.coordinates.rowLabels,
            };

            this.$refs.myTable.hotInstance.updateSettings({
                fixedRowsTop: payload ? fixedRow : 0,
                fixedColumnsLeft: payload ? fixedColumn : 0,
                colHeaders: payload ? true : this.coordinates.columnLabels,
                rowHeaders: payload ? true : this.coordinates.rowLabels,
            });

            if (this.rowHeights.length > 0 && payload) {
                settings.rowHeights = this.rowHeights;
                settings.autoRowSize = false;
            } else {
                settings.autoRowSize = { syncLimit: '40%', allowSampleDuplicates: true };
                settings.rowHeights = 30;
            }

            this.$refs.myTable.hotInstance.updateSettings(settings);
        },

        async getFirstResults(listId) {
            try {
                if (this.distributionId) {
                    const {
                        data: resultData,
                        included: resultIncluded
                    } = await getDistributionResults(this.distributionId, listId, {calc: 0});
                    this.results = resultData;
                    this.resultsIncluded = resultIncluded;
                }

                if (this.templateId) {
                    const {
                        data: resultData,
                        included: resultIncluded
                    } = await getAggregateResults(this.aggregateId, listId);
                    this.results = resultData;
                    this.resultsIncluded = resultIncluded;
                }
            } catch(error) {
                this.$requestError(error.message);
            }
        },

        async getResults(withCalc = true) {
            this.results.forEach((item) => {
                let changed = this.cellData.find((cell) => cell.id === item.relationships.cell.data.id);

                if (changed) {
                    if (withCalc && changed.attributes.calculable) {
                        let index = this.calculatedCells.indexOf(changed.id);
                        if (index !== -1) {
                            this.calculatedCells.splice(index, 1);
                        }
                    }

                    let row = changed.attributes.row;
                    let column = changed.attributes.column;

                    if (this.dataFromTable[row] && this.dataFromTable[row][column]) {
                        /** Присвоение идентификатора результата для изменения ячейки */
                        if (!this.dataFromTable[row][column].resultId) {
                            this.dataFromTable[row][column].resultId = item.id;
                        }

                        let fixed = this.valueTypes[this.dataFromTable[row][column].type].fixed;
                        let value;
                        if (changed.attributes.calculable && fixed > 0) {
                            let newValue = parseFloat(item.attributes.value);
                            value = item.attributes.value && item.attributes.value !== '0'
                                ? newValue.toFixed(fixed) : null;
                        } else {
                            value = (item.attributes.value && item.attributes.value !== '0') ? item.attributes.value : null;
                        }
                        this.transformData[row][column] = value;
                        this.dataFromTable[row][column].layers[0].layersDescr = value;
                    }
                }
            });

            setTimeout(() => {
                this.$refs.myTable?.hotInstance?.render();
            }, 100);
        },

        aggregateCells(cellArray, included, blocked = true) {
            this.calculatedCells = [];
            const modCellArray = cellArray
                .map((cell) => {
                    cell.attributes.row = cell.attributes.row - 1;
                    cell.attributes.column = cell.attributes.column - 1;
                    return cell;
                })
                .sort((a, b) => a.attributes.row - b.attributes.row);

            this.typeLayers = included.filter((layer) => layer.attributes.type === 'type');
            this.hintLayers = included.filter((layer) => layer.attributes.type === 'hint');

            let rowsArray = [];
            for (let index = 0; index <= modCellArray[modCellArray.length - 1].attributes.row; index++) {
                let row = [];

                cellArray.forEach((cell) => {
                    if (cell.attributes.row === index) {
                        if (cell.attributes.calculable && !this.calculatedCells.includes(cell.id)) {
                            this.calculatedCells.push(cell.id);
                        }
                        let type = 'int';
                        let hint = null;
                        if (cell.relationships.layers.data !== [] && this.typeLayers !== null) {
                            cell.relationships.layers.data.forEach((layer) => {
                                let typeLayer = this.typeLayers.find((typeLayer) => typeLayer.id === layer.id);
                                if (typeLayer) {
                                    type = typeLayer.attributes.value;
                                }
                                let hintLayer = this.hintLayers.find((typeLayer) => typeLayer.id === layer.id);
                                if (hintLayer) {
                                    hint = hintLayer.attributes.value;
                                }
                            });
                        }
                        let readOnly = cell.attributes.value || cell.attributes.calculable ? true : blocked;

                        let isHeader = false;
                        if (this.coordinates.fixedRow > 0 || this.coordinates.fixedColumn > 0) {
                            isHeader = cell.attributes.row < this.coordinates.fixedRow || cell.attributes.column < this.coordinates.fixedColumn;
                        }
                        let className = '';
                        if (this.distributionId) {
                            className = isHeader ? 'header ' : (cell.attributes.calculable ? 'blocked ' : (cell.attributes.value ? 'blocked-header' : ''));
                        } else {
                            className = isHeader ? 'header ' : (cell.attributes.calculable ? 'blocked ' : 'blocked-header');
                        }

                        if (cell.attributes.style) {
                            if (cell.attributes.style.includes('bold')){
                                if (className?.length > 0) {
                                    className = `${className} bold-style`;
                                } else {
                                    className = 'bold-style';
                                }
                            }
                            if (cell.attributes.style.includes('italic')){
                                if (className?.length > 0) {
                                    className = `${className} italic-style`;
                                } else {
                                    className = 'italic-style';
                                }
                            }
                            if (cell.attributes.style.includes('strikethrough')){
                                if (className?.length > 0) {
                                    className = `${className} strikethrough-style`;
                                } else {
                                    className = 'strikethrough-style';
                                }
                            }

                            if (cell.attributes.style.includes('underline')){
                                if (className?.length > 0) {
                                    className = `${className} underline-style`;
                                } else {
                                    className = 'underline-style';
                                }
                            }

                            if (cell.attributes.style.includes('background:')) {
                                let styleParam = cell.attributes.style.find(param => param.includes('background:'));
                                let colorArray = styleParam.split('background:');
                                let color = this.colorPalette.find((color) => color.code === colorArray[1]);


                                if (className?.length > 0) {
                                    className = `${className} ${color.code}-background-style`;
                                } else {
                                    className = `${color.code}-background-style`;
                                }
                            }

                            if (cell.attributes.style.includes('color:')) {
                                let styleParam = cell.attributes.style.find(param => param.includes('color:'));
                                let colorArray = styleParam.split('color:');
                                let color = this.colorPalette.find((color) => color.code === colorArray[1]);
                                cell.style.color = `#${color.font}`;

                                if (className?.length > 0) {
                                    className = `${className} ${color.code}-style`;
                                } else {
                                    className = `${color.code}-style`;
                                }
                            }

                            if (cell.attributes.style.includes('font-size:')) {
                                let styleParam = cell.attributes.style.find(param => param.includes('font-size:'));
                                let sizeArray = styleParam.split('font-size:');
                                cell.style.fontSize = sizeArray[1];

                                if (className?.length > 0) {
                                    className = `${className} style-${sizeArray[1]}`;
                                } else {
                                    className = `style-${sizeArray[1]}`;
                                }
                            }
                        }

                        let transformCell = {
                            id: cell.id,
                            head: false,
                            value: '',
                            type: type,
                            readOnly: this.distributionId ? readOnly : true,
                            className: className,
                            layers: [
                                {
                                    layerType: 'text',
                                    layersDescr: cell.attributes.calculable && this.distributionId ? 'Идёт расчёт...'
                                        : (cell.attributes.value && cell.attributes.value !== '0' ? cell.attributes.value : null),
                                    hover: false,
                                    id: uuidv4(),
                                },
                            ],
                            comment: hint ? { value: hint, readOnly: true } : false,
                            attributes: {
                                sharedId: cell.attributes.sharedId,
                                row: cell.attributes.row,
                                column: cell.attributes.column,
                                rowSpan: cell.attributes.rowSpan,
                                columnSpan: cell.attributes.columnSpan,
                                style: cell.attributes.style
                            },
                        };

                        if (cell.attributes.rowSpan > 1 || cell.attributes.columnSpan > 1) {
                            let mergeRowCell = {
                                col: cell.attributes.column,
                                colspan: cell.attributes.columnSpan || 1,
                                row: cell.attributes.row,
                                rowspan: cell.attributes.rowSpan || 1,
                                value: cell.attributes.value,
                            };

                            const haveCopyMergeCell = this.mergeCellsArray.find((cell) => cell.col === mergeRowCell.col && cell.row === mergeRowCell.row);
                            if (!haveCopyMergeCell) this.mergeCellsArray.push(mergeRowCell);
                        }

                        cell.attributes.row === index && row.push(transformCell);
                    }
                });

                rowsArray.push(row);
            }

            this.mergeCellsArray = this.mergeCellsArray.sort((a, b) => (a.row > b.row ? 1 : -1));

            rowsArray.forEach((row) => {
                row.forEach((cell) => {
                    if (cell.attributes.rowSpan > 1 || cell.attributes.columnSpan > 1) {
                        if (cell.attributes.rowSpan > 1) {
                            for (let rowIndex = 0; rowIndex < cell.attributes.rowSpan; rowIndex++) {
                                if (rowIndex === 0) {
                                    for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                        if (cellIndex === 0) continue;
                                        let mockCell = this.generateMockCell(cell.readOnly, cell.attributes.row, cell.attributes.column, 0, cellIndex, cell.className);
                                        rowsArray[cell.attributes.row].push(mockCell);
                                    }
                                } else {
                                    for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                        let mockCell1 = this.generateMockCell(cell.readOnly, cell.attributes.row, cell.attributes.column, rowIndex, cellIndex, cell.className);

                                        rowsArray[cell.attributes.row + rowIndex].push(mockCell1);
                                    }
                                }
                            }
                        } else {
                            for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                if (cellIndex === 0) continue;
                                let mockCell2 = this.generateMockCell(cell.readOnly, cell.attributes.row, cell.attributes.column, 0, cellIndex, cell.className);

                                rowsArray[cell.attributes.row].push(mockCell2);
                            }
                        }
                    }
                });
            });

            rowsArray = rowsArray.map((row) => row.sort((a, b) => (a.attributes.column > b.attributes.column ? 1 : -1)));

            return rowsArray.filter((row) => row.length > 0);
        },

        async saveResult(row, column) {
            if (this.readingMode) {
                return;
            }

            let value = this.transformData[row][column];
            const cell = this.dataFromTable[row][column];

            if (value !== null && value !== '') {
                try {
                    let regexp = this.valueTypes[cell.type].regexp;
                    if (!regexp.test(value) || (cell.type === 'float_quarter' && value % 0.25 !== 0)) {
                        throw Error(`Значение не соответствует типу ${ this.valueTypes[cell.type].label }`);
                    }
                } catch(error) {
                    this.validationError = {
                        cell: `${ this.createCellLayout({
                            sharedId: cell.attributes.sharedId,
                            row: cell.attributes.row + 1,
                            column: cell.attributes.column,
                            worksheetTitle: this.currentWorksheetTitle,
                            coordinates: this.coordinates
                        }) }`,
                        row: cell.attributes.row,
                        column: cell.attributes.column,
                        message: error.message
                    };
                    this.showValidationErrorDialog = true;
                    return;
                }
            }

            const data = {
                data: {
                    type: 'form-distribution-result',
                    attributes: {
                        value: value ? value : null,
                        errors: [],
                    },
                    relationships: {
                        distribution: {
                            data: {
                                type: 'form-distribution',
                                id: this.distributionId,
                            },
                        },
                        cell: {
                            data: {
                                type: 'form-template-cell',
                                id: this.dataFromTable[row][column].id,
                            },
                        },
                    },
                },
            };
            try {
                await saveDistributionResults(this.distributionId, data);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                let fullEdit = this.$refs.myTable?.hotInstance?.getActiveEditor()?.isInFullEditMode();
                let open = this.$refs.myTable?.hotInstance?.getActiveEditor()?.isOpened();
                if (!fullEdit && !open) {
                    /**
                     * Подсветить ошибки, если они были
                     */
                    this.timerError = '';
                    this.highlightErrors();

                    if (0 < this.messages.length) {
                        for (let i = 0; i < this.messages.length; i++) {
                            this.changeBySocket(this.messages[i]);
                        }
                        this.messages = [];

                        setTimeout(() => {
                            this.$refs.myTable?.hotInstance?.render();
                        }, 50);
                    }
                }
            }
        },

        continueFilling({ row, column, cancelChanges }) {
            this.showValidationErrorDialog = false;
            if (cancelChanges) {
                this.transformData[row][column] = this.previousValue;

                setTimeout(() => {
                    this.$refs.myTable?.hotInstance?.render();
                }, 50);
            }
        },

        acceptValue({ row, column, type, value, acceptChanges }) {
            this.showEditedErrorDialog = false;
            if (acceptChanges) {
                let fixed = this.valueTypes[type].fixed;
                if (fixed > 0) {
                    let newValue = parseFloat(value);
                    this.transformData[row][column] = value && value !== '0'
                        ? newValue.toFixed(fixed) : null;
                } else {
                    this.transformData[row][column] = value && value !== '0' ? value : null;
                }

                this.saveResult(row, column);

                setTimeout(() => {
                    this.$refs.myTable?.hotInstance?.render();
                }, 50);
            } else {
                this.saveResult(row, column);
            }
        },

        async updateAggregate() {
            try {
                if (this.aggregateId) {
                    this.$root.reachGoal('full-update-aggregate');
                    await updateAggregateResults(this.aggregateId, this.currentWorksheetId);
                }
            } catch (error) {
                this.$requestError(error.message);
            }
        },

        async updateCalculations(calc = 0) {
            try {
                this.$emit('loadingChange', true);
                this.showWorksheet = false;
                if (this.aggregateId) {
                    this.$root.reachGoal('update-aggregate');
                    const {
                        data: resultData,
                        included: resultIncluded
                    } = await getAggregateResults(this.aggregateId, this.currentWorksheetId, 'y', '');
                    this.results = resultData;
                    this.resultsIncluded = resultIncluded;
                }

                if (this.distributionId) {
                    if (calc == 'full') {
                        this.$root.reachGoal('full-update-distribution');
                    } else {
                        this.$root.reachGoal('update-distribution');
                    }
                    const {
                        data: resultData,
                        included: resultIncluded
                    } = await getDistributionResults(this.distributionId, this.currentWorksheetId, {calc: calc});
                    this.results = resultData;
                    this.resultsIncluded = resultIncluded;
                }

                this.showArrayMap();
                this.$emit('loadingChange', false);

                this.updateData();
                this.showWorksheet = true;
                if (this.results !== []) {
                    await this.getResults(false);
                }
                //this.checkErrors();
            } catch (error) {
                this.$requestError(error.message);
            }
        },

        startErrorTimer(count) {
            if (this.startTimerError) {
                return;
            }
            let startTime = new Date();
            let stopTime = startTime.setSeconds(startTime.getSeconds() + count);

            let that = this;
            let countdown = setInterval(function() {
                let now = new Date().getTime();
                let remain = stopTime - now;

                let min = Math.floor( (remain % (1000 * 60 * 60)) / (1000 * 60) );
                let sec = Math.floor( (remain % (1000 * 60)) / 1000 );
                sec = sec < 10 ? '0' + sec : sec;

                that.timerError = '( ' + min + ':' + sec + ' )';

                if (remain < 0) {
                    clearInterval(countdown);
                    that.timerError = '';
                    that.startTimerError = false;
                    that.highlightErrors();
                }
            }, 1000);

            this.startTimerError = true;
        },

        startTimer(count) {
            if (this.startTimerCalc) {
                return;
            }
            let startTime = new Date();
            let stopTime = startTime.setMinutes(startTime.getMinutes() + count);

            let that = this;
            let countdown = setInterval(function() {
                let now = new Date().getTime();
                let remain = stopTime - now;

                let min = Math.floor( (remain % (1000 * 60 * 60)) / (1000 * 60) );
                let sec = Math.floor( (remain % (1000 * 60)) / 1000 );
                sec = sec < 10 ? '0' + sec : sec;

                that.timerCalc = '( ' + min + ':' + sec + ' )';

                if (remain < 0) {
                    clearInterval(countdown);
                    that.timerCalc = '';
                    that.startTimerCalc = false;
                    that.disabledCalc = false;
                }
            }, 1000);

            this.startTimerCalc = true;
            this.disabledCalc = true;
            this.$toast.add({
                severity: 'info',
                summary: 'Актуализация данных запущена',
                detail: 'Актуализация данных будет доступна через ' + process.env.VUE_APP_AUTOCALC_TIMEOUT + ':00',
                life: 3000
            });
        },

        async sendReview() {
            try {
                this.$root.reachGoal('send-review');

                let data = {};
                if (this.worksheets) {
                    let worksheets = {
                        data: []
                    };

                    this.worksheets.forEach((worksheet) => {
                        let statusData = {};
                        if (this.worksheetStatuses.length > 0) {
                            statusData = this.worksheetStatuses.find((status) => status.relationships.worksheet.data.id === worksheet.id);
                        }

                        if (statusData && statusData.attributes.status !== 'accepted') {
                            worksheets['data'].push({
                                type: 'form-template-worksheet',
                                id: worksheet.id
                            });
                        }
                    });

                    data = {
                        data: {
                            worksheets: worksheets
                        }
                    };
                }

                await sendDistributionReview(this.distributionId, data);

                this.$toast.add({
                    severity: 'success',
                    summary: 'Форма отправлена на проверку',
                    life: '3200',
                });
                window.location.reload();
            } catch (error) {
                this.$requestError(error.message);
            }
        },

        showAcceptForm() {
            this.reviewWorksheets = [];
            this.acceptedWorksheetIds = [];
            this.declinedWorksheetIds = [];
            this.worksheets.forEach((worksheet) => {
                if (this.reviewGroupIds?.length > 0 && worksheet.relationships.group.data?.id
                    && !this.reviewGroupIds.includes(worksheet.relationships.group.data.id)) {
                    return;
                }

                let statusData;

                if (this.worksheetStatuses.length > 0) {
                    statusData = this.worksheetStatuses.find((status) => status.relationships.worksheet.data.id === worksheet.id);
                }

                if (statusData) {
                    worksheet.status = this.formStatuses.find(item => item.value === statusData.attributes.status);
                    if (statusData.attributes.status === 'accepted') {
                        this.acceptedWorksheetIds[worksheet.id] = true;
                        this.declinedWorksheetIds[worksheet.id] = false;
                    } else if (statusData.attributes.status === 'declined') {
                        this.declinedWorksheetIds[worksheet.id] = true;
                        this.acceptedWorksheetIds[worksheet.id] = false;
                    } else {
                        this.acceptedWorksheetIds[worksheet.id] = false;
                        this.declinedWorksheetIds[worksheet.id] = false;
                    }
                } else {
                    this.acceptedWorksheetIds[worksheet.id] = false;
                    this.declinedWorksheetIds[worksheet.id] = false;
                    worksheet.status = this.formStatuses[0];
                }

                if (worksheet.relationships.group.data?.id) {
                    worksheet.group = this.groups.find((group) => group.id === worksheet.relationships.group.data.id);
                } else {
                    worksheet.group = {
                        id: 'noId',
                        header: 'Листы без группы',
                        lists: [],
                        color: '',
                        sort: -1,
                    }
                }

                if ((this.groups?.length > 0 && worksheet.relationships.group.data?.id)
                    || (!worksheet.relationships.group.data?.id && this.groups?.length === 1 && this.groups[0].id === 'noId')) {
                    this.reviewWorksheets.push(worksheet);
                }
            });

            if (this.groups) {
                this.reviewWorksheets.sort((a, b) => (a.group.sort > b.group.sort ? -1 : 1));

                let groupStatuses = [];
                this.reviewWorksheets.forEach((worksheet) => {
                    if (!groupStatuses[worksheet.group.id]) {
                        groupStatuses[worksheet.group.id] = [];
                    }
                    if (groupStatuses[worksheet.group.id][0] !== worksheet.status.value) {
                        groupStatuses[worksheet.group.id].push(worksheet.status.value);
                    }
                });

                Object.keys(groupStatuses).forEach((key) => {
                    if (groupStatuses[key].length === 1) {
                        if (groupStatuses[key][0] === 'accepted') {
                            this.acceptedGroupIds[key] = true;
                            this.declinedGroupIds[key] = false;
                        } else if (groupStatuses[key][0] === 'declined') {
                            this.declinedGroupIds[key] = true;
                            this.acceptedGroupIds[key] = false;
                        } else {
                            this.declinedGroupIds[key] = false;
                            this.acceptedGroupIds[key] = false;
                        }
                    } else {
                        this.declinedGroupIds[key] = false;
                        this.acceptedGroupIds[key] = false;
                    }
                });
            }

            this.showAcceptFormDialog = true;
        },

        async loadFormDistributionCheckData() {
            this.formDistributionCheckData = null;
            this.loadErrorsState = true;
            this.errorList = [];

            let data = '';
            this.distributionId
                ? await getDistributionCheck(this.distributionId, data)
                : await getAggregateCheck(this.aggregateId, data);
        },

        async loadErrors() {
            try {
                this.errorList = [];
                let worksheets = this.worksheets;
                let worksheetErrors = this.aggregateErrors(this.formDistributionCheckData.items.data, this.formDistributionCheckData.items.included ? this.formDistributionCheckData.items.included : [], worksheets, true);
                if (worksheetErrors.length) {
                    this.$emit('loadingChange');
                    this.errorList = this.errorList.concat(worksheetErrors);
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.errorGroups = [];

                let worksheetData = null;
                this.errorList = this.errorList.sort((a, b) => (a.worksheet.id > b.worksheet.id ? 1 : -1));

                this.errorList.forEach((error) => {
                    if (error.id) {
                        if (!worksheetData) {
                            worksheetData = {
                                id: error.worksheet.id,
                                header: error.worksheet.attributes.name,
                                errors: [error]
                            }
                        } else {
                            if (worksheetData.id === error.worksheet.id) {
                                worksheetData.errors.push(error);
                            } else {
                                this.errorGroups.push(worksheetData);
                                let findWorksheet = this.errorGroups.find(worksheet => worksheet.id === error.worksheet.id);
                                if (findWorksheet) {
                                    findWorksheet.errors.push(error);
                                    worksheetData = null;
                                } else {
                                    worksheetData = {
                                        id: error.worksheet.id,
                                        header: error.worksheet.attributes.name,
                                        sort: error.worksheet.attributes.sort,
                                        errors: [error]
                                    }
                                }
                            }
                        }
                    }
                });
                if (worksheetData) {
                    this.errorGroups.push(worksheetData);
                }
            }
            this.errorGroups = this.errorGroups.sort((a, b) => (a.sort > b.sort ? 1 : -1));
            this.loadErrorsState = false;
        },

        closeCheckDialog() {
            this.showCheckDialog = false;
        },

        closeAcceptFormDialog() {
            this.showAcceptFormDialog = false;
        },

        async uploadData(file) {
            this.$emit('loadingChange', true);
            try {
                await uploadData(this.distributionId, file);
                window.location.reload();
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async acceptForm(acceptedWorksheets = [], declinedWorksheets = [], comments = []) {
            let acceptFormData = [];

            this.reviewWorksheets.forEach((worksheet) => {
                if (acceptedWorksheets[worksheet.id]) {
                    acceptFormData.push({
                        status: 'accepted',
                        comment: comments[worksheet.id],
                        worksheet: {
                            data: {
                                type: "form-distribution-worksheet",
                                id: worksheet.id
                            }
                        }
                    });
                } else if (declinedWorksheets[worksheet.id]) {
                    acceptFormData.push({
                        status: 'declined',
                        comment: comments[worksheet.id],
                        worksheet: {
                            data: {
                                type: "form-distribution-worksheet",
                                id: worksheet.id
                            }
                        }
                    });
                } else {
                    acceptFormData.push({
                        status: 'sent',
                        comment: comments[worksheet.id],
                        worksheet: {
                            data: {
                                type: "form-distribution-worksheet",
                                id: worksheet.id
                            }
                        }
                    });
                }
            });
            const data = {
                distributions: {
                    data: this.acceptDistributions
                },
                review: {
                    data: acceptFormData
                }
            };
            try {
                const result = await submitDistributionReview(this.distributionId, data);

                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.showAcceptFormDialog = false;
                window.location.reload();
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async approveForm() {
            try {
                await approveDistributionReview({
                    distributions: {
                        data: [{
                            type: "form-distribution",
                            id: this.formDistributionId
                        }]
                    }
                });

                this.$toast.add({
                    severity: 'success',
                    summary: 'Форма успешно утверждена',
                    life: '3200',
                });
                window.location.reload();
            } catch (error) {
                this.$requestError(error.message);
            }
        },

        async revokeApproval() {
            try {
                await revokeDistributionApproval({
                    distributions: {
                        data: [{
                            type: "form-distribution",
                            id: this.formDistributionId
                        }]
                    }
                });

                this.$toast.add({
                    severity: 'success',
                    summary: 'Подтверждение результатов проверки формы отменено успешно',
                    life: '3200',
                });
                window.location.reload();
            } catch (error) {
                this.$requestError(error.message);
            }
        },

        formatComments(data, included) {
            let commentsArray = data.map((comment) => {
                const userId = comment.relationships.author.data ? comment.relationships.author.data.id : null;

                let user = null;
                if (userId) user = included ? included.filter((item) => item.id === userId) : null;

                return {
                    date: comment.attributes.createdAt,
                    userId: userId,
                    name: user ? `${user[0].attributes.firstName} ${user[0].attributes.lastName}` : 'Пользователь не указан',
                    id: comment.id,
                    message: comment.attributes.message,
                    deleted: comment.attributes.deleted,
                    timeStamp: +new Date(comment.attributes.createdAt),
                };
            });

            return commentsArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));
        },

        generateMockCell(editable, row, column, rowCoof = 0, columnCoof = 0, className = '') {
            return {
                type: 'form-template-cell',
                id: uuidv4(),
                mock: true,
                readOnly: editable,
                attributes: {
                    sharedId: uuidv4(),
                    row: row + rowCoof,
                    column: column + columnCoof,
                    rowSpan: 1,
                    columnSpan: 1,
                    value: '',
                    editable: false,
                },
                className: className,
                layers: [
                    {
                        layerType: 'text',
                        layersDescr: `ROW${row} ${column}`,
                        hover: false,
                        id: uuidv4(),
                    },
                ],
            };
        },

        agregateHistory(userData, versionIncluded) {
            let historyArray = userData.map((historyItem) => {
                const userId = historyItem.relationships.createdBy.data ? historyItem.relationships.createdBy.data.id : null;
                let user = null;
                if (userId) user = versionIncluded ? versionIncluded.filter((item) => item.id === userId) : null;
                return { date: historyItem.attributes.createdAt, name: user ? `${user[0].attributes.firstName} ${user[0].attributes.lastName}` : 'Авторасчёт', id: historyItem.id, timeStamp: +new Date(historyItem.attributes.createdAt) };
            });

            return historyArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));
        },

        aggregateErrors(errorData, cellData, worksheets = null, isCheckAllWorksheets = false) {
            let errorArray = [];

            if (!isCheckAllWorksheets) {
                this.errorCells = [];
            }
            if (errorData) {
                errorData.forEach((error) => {
                    let cell = cellData.find((cell) => cell.id === error.relationships.cell.data.id);
                    let worksheet = cellData.find((worksheet) => worksheet.id === cell.relationships.worksheet.data.id);

                    let formula = '';
                    let layer = cellData.find((layer) => layer.id === error.relationships.layer.data.id);
                    if (layer) {
                        let formulaArray = layer.attributes.value.split(/cell\('|'\)/);
                        formula = formulaArray
                            .map((item) => {
                                if (item.length === 36) {
                                    let sharedCell = cellData.find((cell) => cell.attributes.sharedId === item);
                                    if (!sharedCell) {
                                        this.$toast.add({
                                            severity: 'error',
                                            summary: `Не получена информация по ячейке с sharedId: ${ item }. Загрузка ошибок невозможна.`,
                                            life: '3500',
                                        });

                                        return `cell(${ item })`;
                                    } else {
                                        const sharedCellWorksheet = cellData.find((worksheet) => worksheet.id === sharedCell?.relationships.worksheet.data.id);
                                        const sharedCellTemplate = cellData.find((template) => template.id === sharedCellWorksheet?.relationships?.template.data.id);
                                        const sharedCellForm = cellData.find((form) => form.id === sharedCellTemplate?.relationships.form.data.id);

                                        let cellObject = {
                                            sharedId: sharedCell.attributes.sharedId,
                                            row: sharedCell.attributes.row,
                                            column: sharedCell.attributes.column - 1,
                                            worksheetTitle: sharedCellWorksheet.id ? sharedCellWorksheet.attributes.name : worksheet.attributes.name,
                                            coordinates: sharedCellWorksheet.id ? {
                                                rowLabels: sharedCellWorksheet.attributes.properties?.rowLabels || [],
                                                columnLabels: sharedCellWorksheet.attributes.properties?.columnLabels || []
                                            } : this.coordinates
                                        }
                                        if (worksheet.relationships?.template.data.id !== sharedCellTemplate.id) {
                                            cellObject.templateTitle = sharedCellTemplate?.attributes.name;
                                            cellObject.formTitle = sharedCellForm?.attributes.name
                                        }

                                        return `${ this.createCellLayout(cellObject) }`;
                                    }
                                }

                                let prevArray = item.split(/prev_period\('|'\)/);
                                item = prevArray
                                    .map((item) => {
                                        if (item.length === 36) {
                                            let sharedCell = cellData.find((cell) => cell.attributes.sharedId === item);
                                            if (!sharedCell) {
                                                this.$toast.add({
                                                    severity: 'error',
                                                    summary: `Не получена информация по ячейке с sharedId: ${ item }. Загрузка ошибок невозможна.`,
                                                    life: '3500',
                                                });

                                                return `prev_period(${ item })`;
                                            } else {
                                                const sharedCellWorksheet = cellData.find((worksheet) => worksheet.id === sharedCell?.relationships.worksheet.data.id);
                                                const sharedCellTemplate = cellData.find((template) => template.id === sharedCellWorksheet.relationships?.template.data.id);
                                                const sharedCellForm = cellData.find((form) => form.id === sharedCellTemplate?.relationships.form.data.id);

                                                let cellObject = {
                                                    sharedId: sharedCell.attributes.sharedId,
                                                    row: sharedCell.attributes.row,
                                                    column: sharedCell.attributes.column - 1,
                                                    worksheetTitle: sharedCellWorksheet?.attributes.name,
                                                    coordinates: sharedCellWorksheet.id ? {
                                                        rowLabels: sharedCellWorksheet.attributes.properties?.rowLabels || [],
                                                        columnLabels: sharedCellWorksheet.attributes.properties?.columnLabels || []
                                                    } : this.coordinates
                                                }
                                                if (worksheet.relationships?.template.data.id !== sharedCellTemplate.id) {
                                                    cellObject.templateTitle = sharedCellTemplate?.attributes.name;
                                                    cellObject.formTitle = sharedCellForm?.attributes.name;
                                                }

                                                return `prev_period(${this.createCellLayout(cellObject)})`;
                                            }
                                        }

                                        return item;
                                    }).join(' ');

                                let accumulateArray = item.split(/accumulate\('|'\)/);
                                item = accumulateArray.map((item) => {
                                    if (item.length === 36) {
                                        let sharedCell = cellData.find((cell) => cell.attributes.sharedId === item);
                                        if (!sharedCell) {
                                            this.$toast.add({
                                                severity: 'error',
                                                summary: `Не получена информация по ячейке с sharedId: ${ item }. Загрузка ошибок невозможна.`,
                                                life: '3500',
                                            });

                                            return `accumulate(${ item })`;
                                        } else {
                                            const sharedCellWorksheet = cellData.find((worksheet) => worksheet.id === sharedCell?.relationships.worksheet.data.id);
                                            const sharedCellTemplate = cellData.find((template) => template.id === sharedCellWorksheet.relationships?.template.data.id);
                                            const sharedCellForm = cellData.find((form) => form.id === sharedCellTemplate?.relationships.form.data.id);

                                            let cellObject = {
                                                sharedId: sharedCell.attributes.sharedId,
                                                row: sharedCell.attributes.row,
                                                column: sharedCell.attributes.column - 1,
                                                worksheetTitle: sharedCellWorksheet?.attributes.name,
                                                coordinates: sharedCellWorksheet.id ? {
                                                    rowLabels: sharedCellWorksheet.attributes.properties?.rowLabels || [],
                                                    columnLabels: sharedCellWorksheet.attributes.properties?.columnLabels || []
                                                } : this.coordinates
                                            }
                                            if (worksheet.relationships?.template.data.id !== sharedCellTemplate.id) {
                                                cellObject.templateTitle = sharedCellTemplate?.attributes.name;
                                                cellObject.formTitle = sharedCellForm?.attributes.name
                                            }

                                            return `accumulate(${this.createCellLayout(cellObject)})`;
                                        }
                                    }

                                    return item;
                                }).join(' ');

                                let getByTagArray = item.split(/getByTag\('|'\)/);
                                item = getByTagArray.map((item) => {
                                    const [cellSharedId, tagString] = item.split('\',\'');
                                    if (cellSharedId.length === 36) {
                                        let sharedCell = cellData.find((cell) => cell.attributes.sharedId === cellSharedId);
                                        if (!sharedCell) {
                                            this.$toast.add({
                                                severity: 'error',
                                                summary: `Не получена информация по ячейке с sharedId: ${ item }. Загрузка ошибок невозможна.`,
                                                life: '3500',
                                            });

                                            return `accumulate(${ item })`;
                                        } else {
                                            const sharedCellWorksheet = cellData.find((worksheet) => worksheet.id === sharedCell?.relationships.worksheet.data.id);
                                            const sharedCellTemplate = cellData.find((template) => template.id === sharedCellWorksheet.relationships?.template.data.id);
                                            const sharedCellForm = cellData.find((form) => form.id === sharedCellTemplate?.relationships.form.data.id);

                                            let cellObject = {
                                                sharedId: sharedCell.attributes.sharedId,
                                                row: sharedCell.attributes.row,
                                                column: sharedCell.attributes.column - 1,
                                                worksheetTitle: sharedCellWorksheet?.attributes.name,
                                                coordinates: sharedCellWorksheet.id ? {
                                                    rowLabels: sharedCellWorksheet.attributes.properties?.rowLabels || [],
                                                    columnLabels: sharedCellWorksheet.attributes.properties?.columnLabels || []
                                                } : this.coordinates
                                            }
                                            if (worksheet.relationships?.template.data.id !== sharedCellTemplate.id) {
                                                cellObject.templateTitle = sharedCellTemplate?.attributes.name;
                                                cellObject.formTitle = sharedCellForm?.attributes.name
                                            }

                                            return `getByTag(${this.createCellLayout(cellObject)},${tagString})`;
                                        }
                                    }

                                    return item;
                                }).join(' ');

                                return item;
                            })
                            .join(' ');
                    }

                    if (!isCheckAllWorksheets) {
                        this.errorCells.push({row: cell.attributes.row - 1, column: cell.attributes.column - 1});
                    }
                    errorArray.push({
                        id: cell.attributes.sharedId,
                        cell: `${ this.createCellLayout({
                            sharedId: cell.attributes.sharedId,
                            row: cell.attributes.row,
                            column: cell.attributes.column - 1,
                            worksheetTitle: worksheet.attributes.name,
                            coordinates: worksheet.id ? {
                                rowLabels: worksheet.attributes.properties?.rowLabels || [],
                                columnLabels: worksheet.attributes.properties?.columnLabels || []
                            } : this.coordinates
                        }) }`,
                        formula: formula,
                        worksheet: worksheet,
                        message: error.attributes.message,
                        explain: error.attributes.explain,
                        sort: worksheet.attributes.sort
                    });
                });
            }

            if (isCheckAllWorksheets) {
                let worksheetId = null;
                for (const worksheet of worksheets) {
                    worksheetId = worksheet.id;

                    let distributionWorksheet = this.worksheetStatuses.find((item) => item.relationships.worksheet.data.id === worksheetId);

                    if (distributionWorksheet && distributionWorksheet.attributes.status === 'new') {
                        errorArray.push({
                            message: 'На данном листе не производился ввод данных, поэтому список ошибок может быть не полным.',
                            worksheet: worksheet,
                            sort: worksheet.attributes.sort
                        });
                    } else {
                        let worksheetWithErrors = errorArray.find((item) => item.worksheet.id === worksheetId);

                        if (!worksheetWithErrors) {
                            errorArray.push({
                                message: 'Ошибок не обнаружено.',
                                worksheet: worksheet,
                                sort: worksheet.attributes.sort
                            });
                        }
                    }
                }
            } else {
                let fullEdit = this.$refs.myTable?.hotInstance?.getActiveEditor()?.isInFullEditMode();
                let open = this.$refs.myTable?.hotInstance?.getActiveEditor()?.isOpened();
                if (!fullEdit && !open) {
                    if (this.timerError.length) {
                        return;
                    } else {
                        this.startErrorTimer(Number(process.env.VUE_APP_HIGHLIGHT_ERRORS));
                    }
                }
            }

            return errorArray;
        },

        createCellLayout({
            sharedId,
            row,
            column,
            worksheetTitle,
            templateTitle = null,
            formTitle = null,
            periodId = null,
            prevPeriodMode,
            cellWithOtherForm,
            coordinates
        }) {

            const periodInfo = periodId ? `data-period-id=${ periodId }` : '';
            const prevPeriodInfo = prevPeriodMode ? `data-prev-period=${ prevPeriodMode }` : '';
            const cellWithOtherFormInfo = cellWithOtherForm ? `data-other-form-cell=${ cellWithOtherForm }` : '';
            let columnName = this.$refs.myTable?.hotInstance?.getColHeader(column);
            let rowName = row;
            if (coordinates && coordinates.columnLabels[column] && coordinates.rowLabels[row - 1]) {
                columnName = `грф.${ coordinates.columnLabels[column] }:`;
                rowName = `стр.${ coordinates.rowLabels[row - 1] }`;
            }

            if (templateTitle && formTitle) {
                return `<span class="cell-marker" data-shared-id="${ sharedId }" ${ periodId ? periodInfo : '' }  ${ prevPeriodMode ? prevPeriodInfo : '' } ${
                    cellWithOtherForm ? cellWithOtherFormInfo : ''
                } data-row="${ row - 1 }" data-column="${ column }">(${ templateTitle }) Форма ${ formTitle }: Лист '${ worksheetTitle }' ${ columnName }${ rowName }</span>  `;
            }

            return `<span class="cell-marker" data-shared-id="${ sharedId }" data-row="${ row - 1 }" data-column="${ column }">Лист '${ worksheetTitle }' ${ columnName }${ rowName }</span>  `;
        },

        updateData() {
            this.transformData = this.getTableHeaders(this.dataFromTable);
            this.$refs.myTable?.hotInstance?.updateSettings({
                data: this.transformData,
                mergeCells: this.mergeCellsArray,
            });
        },

        getTableHeaders(dataFromTable) {
            let headers = [];

            dataFromTable.forEach((cell) => {
                headers = [...headers, cell.map((data) => data.layers[0].layersDescr)];
            });
            return headers;
        },

        showRightPanel() {
            this.showRightMenu = true;
            setTimeout(() => {
                if (0 === this.activeTab) {
                    this.$refs.myTable?.hotInstance?.render();
                } else {
                    this.$refs.sliceTable?.hotInstance?.render();
                }
            }, 100);
        },

        hideRightPanel() {
            this.showRightMenu = false;
            this.selectedPanel = null;

            setTimeout(() => {
                if (0 === this.activeTab) {
                    this.$refs.myTable?.hotInstance?.render();
                } else {
                    this.$refs.sliceTable?.hotInstance?.render();
                }
            }, 100);
        },

        changeComponent(button) {
            this.selectedPanel = this.rightPanels.filter((mode) => mode.label === button.label)[0];
        },

        loadingChange(payload = false) {
            this.loading = payload;
        },

        async startUnloading(formDistributionId, reportId) {
            try {
                this.$root.reachGoal('upload');
                this.$emit('loadingChange', true);
                const result = await sendUnloadingData(formDistributionId, reportId);

                if (result.status === 204)
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Запрос на генерацию отчета поставлен в очередь, перейдите в раздел выгрузок',
                        life: 2500,
                    });
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
        },

        async startAggregateUnloading(formDistributionId, reportId) {
            try {
                this.$root.reachGoal('upload');
                this.$emit('loadingChange', true);
                const result = await sendAggregateUnloadingData(formDistributionId, reportId);

                if (result.status === 204)
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Запрос на генерацию отчета поставлен в очередь, перейдите в раздел выгрузок',
                        life: 2500,
                    });
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
        },

        async downloadReport(reportId) {
            try {
                this.$root.reachGoal('make-report');
                this.$emit('loadingChange', true);
                const result = this.aggregateId
                    ? await downloadAggregateReport(this.aggregateId, reportId)
                    : await downloadDistributionReport(this.distributionId, reportId);

                if (result.status === 204) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Запрос на генерацию отчёта поставлен в очередь, перейдите в раздел выгрузок',
                        life: 2500,
                    });
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async createAggregate(formDistributionId) {
            this.$emit('loadingChange', true);
            try {
                const { data: aggregateResult } = await aggregateDistribution(formDistributionId);

                let routeData;
                if (this.$route.params.worksheetId) {
                    routeData = this.$router.resolve({
                        path: `/aggregate/${ this.distributionTemplateId }/${ aggregateResult.data.id }/${ formDistributionId }/${ this.$route.params.worksheetId }/`,
                    });
                } else {
                    routeData = this.$router.resolve({
                        path: `/aggregate/${this.distributionTemplateId}/${aggregateResult.data.id}/${formDistributionId}/`,
                    });
                }
                this.$root.reachGoal('open-aggregate');
                window.open(routeData.href, '_blank');
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
        },

        initConnection(token, channel) {
            const Centrifuge = require('centrifuge');
            let centrifuge;

            if (centrifuge && centrifuge.isConnected()) {
                centrifuge.disconnect();
            }

            centrifuge = new Centrifuge(`${ socketUrl }/connection/websocket`, {
                debug: false
            });
            centrifuge.setToken(token);

            const that = this;
            centrifuge.on('connect', function () {
                let subscription;

                subscription = centrifuge.subscribe(channel, function (message) {

                    if (message.data) {
                        /*let values = '';
                        if (message.data?.items.length) {
                            for (const item of message.data.items) {
                                values += `${item.value}, `;
                            }
                        }*/

                        //console.log(new Date(), message.data.type, values, message.data.items?.status);

                        if (message.data.type === 'distribution-result-changed') {
                            let editor = that.$refs.myTable?.hotInstance?.getActiveEditor();
                            let fullEdit = that.$refs.myTable?.hotInstance?.getActiveEditor()?.isInFullEditMode();
                            let open = that.$refs.myTable?.hotInstance?.getActiveEditor()?.isOpened();
                            that.editedCell = {
                                col: editor?.col,
                                row: editor?.row,
                            }
                            if (!fullEdit && !open) {
                                that.changeBySocket(message.data);
                                setTimeout(() => {
                                    that.$refs.myTable?.hotInstance?.render();
                                }, 50);
                            } else {
                                that.messages.push(message.data);
                            }

                        }

                        if (message.data.type === 'form-distribution-check-status') {
                            if (message.data.items.worksheets[0] === that.currentWorksheetId) {
                                if (that.isCheckWorksheet !== message.data.items.status && message.data.items.status !== 'from_cache' && message.data.items.status !== 'job_finish') {
                                    that.isCheckWorksheet = message.data.items.status;
                                } else {
                                    that.isCheckWorksheet = false;
                                }
                                that.checkedStatus = CHECK_STATUSES[message.data.items.status];
                            }
                            if (message.data.items.worksheets?.length === 0) {
                                if (that.isCheckFormWorksheet !== message.data.items.status && message.data.items.status !== 'from_cache' && message.data.items.status !== 'job_finish') {
                                    that.isCheckFormWorksheet = message.data.items.status;
                                } else {
                                    that.isCheckFormWorksheet = false;
                                }
                                that.checkedFormStatus = CHECK_STATUSES[message.data.items.status];
                                if (message.data.items.status === 'job_runnig') {
                                    that.$toast.add({
                                        severity: 'info',
                                        summary: 'Задание уже в очереди',
                                        life: '3500',
                                    });
                                }
                                if (message.data.items.status === 'move_to_queue') {
                                    that.$toast.add({
                                        severity: 'info',
                                        summary: 'Задание поставлено в очередь',
                                        life: '3500',
                                    });
                                }
                                if (message.data.items.status === 'job_finish') {
                                    that.$toast.add({
                                        severity: 'success',
                                        summary: 'Проверка формы завершена',
                                        life: '3500',
                                    });
                                }
                            }
                        }

                        if (message.data.type === 'aggregate-check-status') {
                            if (message.data.items.worksheets && message.data.items.worksheets[0] === that.currentWorksheetId) {
                                if (that.isCheckWorksheet !== message.data.items.status && message.data.items.status !== 'from_cache' && message.data.items.status !== 'job_finish') {
                                    that.isCheckWorksheet = message.data.items.status;
                                } else {
                                    that.isCheckWorksheet = false;
                                }
                                that.checkedStatus = CHECK_STATUSES[message.data.items.status];
                            }
                            if (message.data.items.worksheets?.length === 0) {
                                that.checkedFormStatus = CHECK_STATUSES[message.data.items.status];
                                if (message.data.items.status === 'job_runnig') {
                                    that.$toast.add({
                                        severity: 'info',
                                        summary: 'Задание уже в очереди',
                                        life: '3500',
                                    });
                                }
                                if (message.data.items.status === 'move_to_queue') {
                                    that.$toast.add({
                                        severity: 'info',
                                        summary: 'Задание поставлено в очередь',
                                        life: '3500',
                                    });
                                }
                                if (message.data.items.status === 'job_finish') {
                                    that.$toast.add({
                                        severity: 'success',
                                        summary: 'Проверка свода завершена',
                                        life: '3500',
                                    });
                                }
                            }
                        }

                        if (message.data.type === 'aggregate-refresh-status') {
                            if (message.data.items.worksheet === that.currentWorksheetId) {
                                if (message.data.items.status === 'job_process') {
                                    that.refreshStatus = `${ message.data.items.stage } из ${ message.data.items.total }`;
                                    that.isRefreshWorksheet = true;
                                } else {
                                    that.isRefreshWorksheet = false;
                                }
                                if (message.data.items.status === 'job_runnig') {
                                    that.$toast.add({
                                        severity: 'info',
                                        summary: 'Задание уже в очереди',
                                        life: '3500',
                                    });
                                }
                                if (message.data.items.status === 'move_to_queue') {
                                    that.$toast.add({
                                        severity: 'info',
                                        summary: 'Задание поставлено в очередь',
                                        life: '3500',
                                    });
                                }
                                if (message.data.items.status === 'job_finish') {
                                    that.$toast.add({
                                        severity: 'success',
                                        summary: 'Актуализация завершена',
                                        life: '3500',
                                    });
                                }
                            }
                        }

                        if (message.data.type === 'form-distribution-check-result') {
                            that.formDistributionCheckData = message.data;
                        }

                        if (message.data.type === 'aggregate-check-result') {
                            that.formDistributionCheckData = message.data;
                        }
                    }
                });

                subscription.on('error', function (message) {
                    console.log('Error subscribing on channel', message);
                });
            });

            centrifuge.connect();
        },

        changeBySocket(data) {
            data.items.forEach((item) => {
                let changed = this.cellData.find((cell) => cell.id === item.cellId);
                if (changed) {
                    let row = changed.attributes.row;
                    let column = changed.attributes.column;
                    const cell = this.dataFromTable[row][column];

                   if (this.editedCell && this.editedCell.row === row && this.editedCell.col === column && this.transformData[row][column] != null && item.value !== this.transformData[row][column]) {
                        this.editedError = {
                            cell: `${ this.createCellLayout({
                                sharedId: cell.attributes.sharedId,
                                row: cell.attributes.row + 1,
                                column: cell.attributes.column,
                                worksheetTitle: this.currentWorksheetTitle,
                                coordinates: this.coordinates
                            }) }`,
                            row: cell.attributes.row,
                            column: cell.attributes.column,
                            message: `Данные в редактируемой ячейке изменились, новое значение: ${item.value}`,
                            type: cell.type,
                            value: item.value,
                            oldValue: this.transformData[row][column]
                        };
                        this.showEditedErrorDialog = true;
                        return;
                    }

                    if (this.transformData[row][column] !== item.value) {
                        let fixed = this.valueTypes[cell.type].fixed;
                        if (changed.attributes.calculable && fixed > 0) {
                            let newValue = parseFloat(item.value);
                            this.transformData[row][column] = item.value && item.value !== '0'
                                ? newValue.toFixed(fixed) : null;
                        } else {
                            this.transformData[row][column] = item.value && item.value !== '0' ? item.value : null;
                        }
                    }
                }
            });
        },

        async createSlice(type, number) {
            this.$emit('loadingChange', true);
            try {
                const sliceResults = await createSlice(this.aggregateId, this.currentWorksheetId, type, number);

                this.$emit('loadingChange', false);
                if (sliceResults !== []) {
                    this.showWorksheet = false;
                    this.mergeSliceCellsArray = [];
                    this.dataFromSlice = this.aggregateSliceCells(sliceResults);
                    this.showArrayMap();

                    const sliceNumber = this.tabs.length;
                    this.tabs.push({
                        id: sliceNumber,
                        label: `Срез ${ sliceNumber }`,
                        worksheet: this.currentWorksheetId,
                        type: type,
                        number: number
                    });

                    for (let i = 0; i < sliceNumber; i++) {
                        this.activeTabIds[i] = false;
                    }
                    this.activeTabIds.push(true);
                    this.activeTab = sliceNumber;

                    this.transformSliceData[this.activeTab] = this.getTableHeaders(this.dataFromSlice);
                    this.mergeCellsData[this.activeTab] = this.mergeSliceCellsArray;
                    this.showSlice = true;

                    setTimeout(() => {
                        this.$refs.sliceTable?.hotInstance?.updateSettings({
                            data: this.transformSliceData[this.activeTab],
                            mergeCells: this.mergeCellsData[this.activeTab],
                            language: 'en-US'
                        });
                    }, 100);
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        aggregateSliceCells(sliceCells) {
            let headerHeight = 0;
            let maxColumn = 0;

            const sliceCellsArray = sliceCells.map((cell) => {
                if (headerHeight === 0 && cell.attributes.value === 'Организация' && cell.attributes.rowSpan > 0) {
                    headerHeight = cell.attributes.rowSpan;
                }

                if (maxColumn < cell.attributes.column) {
                    maxColumn = cell.attributes.column;
                }

                cell.attributes.row = cell.attributes.row - 1;
                cell.attributes.column = cell.attributes.column - 1;

                return cell;
            }).sort((a, b) => a.attributes.row - b.attributes.row);

            let rowsArray = []
            let countCells = sliceCellsArray.length - 1;
            for (let index = 0; index <= sliceCellsArray[countCells].attributes.row; index++) {
                let row = [];

                sliceCells.forEach((cell) => {
                    if (cell.attributes.row === index) {
                        let transformCell = {
                            id: cell.id,
                            head: false,
                            value: '',
                            readOnly: true,
                            className: '',
                            layers: [
                                {
                                    layerType: 'text',
                                    layersDescr: cell.attributes.value && cell.attributes.value !== '0' ? cell.attributes.value : null,
                                    id: uuidv4(),
                                },
                            ],
                            attributes: {
                                row: cell.attributes.row,
                                column: cell.attributes.column,
                                rowSpan: cell.attributes.rowSpan,
                                columnSpan: cell.attributes.columnSpan,
                                header: cell.attributes.header
                            },
                        };

                        if (cell.attributes.rowSpan > 1 || cell.attributes.columnSpan > 1) {
                            let mergeRowCell = {
                                col: cell.attributes.column,
                                colspan: cell.attributes.columnSpan || 1,
                                row: cell.attributes.row,
                                rowspan: cell.attributes.rowSpan || 1,
                                value: cell.attributes.value,
                            };

                            const haveCopyMergeCell = this.mergeSliceCellsArray.find((cell) => cell.col === mergeRowCell.col && cell.row === mergeRowCell.row);
                            if (!haveCopyMergeCell) this.mergeSliceCellsArray.push(mergeRowCell);
                        }

                        let idx = row.findIndex(rowCell => rowCell.attributes.column === cell.attributes.column && rowCell.attributes.row === cell.attributes.row);
                        if (idx > -1) {
                            row.splice(idx, 1);
                        }
                        cell.attributes.row === index && row.push(transformCell);
                    }
                });
                rowsArray.push(row);
            }

            this.mergeSliceCellsArray = this.mergeSliceCellsArray.sort((a, b) => (a.row > b.row ? 1 : -1));
            rowsArray = rowsArray.map((row) => row.sort((a, b) => (a.attributes.column > b.attributes.column ? 1 : -1)));

            rowsArray.forEach((row) => {
                let prevColumn = 0;
                row.forEach((cell) => {
                    if (cell.attributes.rowSpan > 1 || cell.attributes.columnSpan > 1) {
                        if (cell.attributes.rowSpan > 1) {
                            for (let rowIndex = 0; rowIndex < cell.attributes.rowSpan; rowIndex++) {
                                if (rowIndex === 0) {
                                    for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                        if (cellIndex === 0) continue;
                                        let mockCell = this.generateSliceMockCell(cell.attributes.row, cell.attributes.column, 0, cellIndex);
                                        rowsArray[cell.attributes.row].push(mockCell);
                                    }
                                } else {
                                    for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                        let mockCell1 = this.generateSliceMockCell(cell.attributes.row, cell.attributes.column, rowIndex, cellIndex);

                                        rowsArray[cell.attributes.row + rowIndex].push(mockCell1);
                                    }
                                }
                            }
                        } else {
                            for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                if (cellIndex === 0) continue;
                                let mockCell2 = this.generateSliceMockCell(cell.attributes.row, cell.attributes.column, 0, cellIndex);

                                rowsArray[cell.attributes.row].push(mockCell2);
                            }
                        }
                    }
                    if (cell.attributes.rowSpan === null && cell.attributes.columnSpan === null && cell.attributes.row > headerHeight - 1) {
                        /** Если отсутствуют несколько ячеек - заполним их пустыми ячейками */
                        if (prevColumn < cell.attributes.column - 1) {
                            for (let cellIndex = prevColumn + 1; cellIndex < cell.attributes.column; cellIndex++) {
                                let mockCell3 = this.generateSliceMockCell(cell.attributes.row, cellIndex, 0, 0, '');

                                rowsArray[cell.attributes.row].push(mockCell3);
                            }
                        }
                        /** Если отсутствует шапка вверху - заполним пустыми ячейками */
                        if (headerHeight === 0 && !cell.attributes.header) {
                            for (let cellIndex = 0; cellIndex < cell.attributes.row; cellIndex++) {
                                if (!rowsArray[cellIndex][cell.attributes.column]) {
                                    let mockCell3 = this.generateSliceMockCell(cellIndex, cell.attributes.column, 0, 0, '');

                                    rowsArray[cellIndex].push(mockCell3);
                                }
                            }
                        }
                    }

                    // Для сводов без левой шапки
                    if (this.coordinates.fixedColumn === 0 && cell.attributes.row === 0) {
                        if (!rowsArray[cell.attributes.row][prevColumn + 1]) {
                            for (let cellIndex = prevColumn + 1; cellIndex < maxColumn; cellIndex++) {
                                let mockCell3 = this.generateSliceMockCell(cell.attributes.row, cellIndex, 0, 0, '');
                                rowsArray[cell.attributes.row].push(mockCell3);
                            }
                        }
                    }

                    prevColumn = cell.attributes.column + cell.attributes.columnSpan;
                });
            });

            rowsArray = rowsArray.map((row) => row.sort((a, b) => (a.attributes.column > b.attributes.column ? 1 : -1)));

            return rowsArray.filter((row) => row.length > 0);
        },

        generateSliceMockCell(row, column, rowCoof = 0, columnCoof = 0, value = null) {
            return {
                type: 'form-template-cell',
                id: uuidv4(),
                mock: true,
                readOnly: true,
                attributes: {
                    row: row + rowCoof,
                    column: column + columnCoof,
                    rowSpan: 1,
                    columnSpan: 1,
                    value: ''
                },
                className: '',
                layers: [
                    {
                        layerType: 'text',
                        layersDescr: value !== null ? value : `ROW${row} ${column}`,
                        id: uuidv4()
                    },
                ],
            };
        },

        changeSliceTabs() {
            this.activeTab = this.activeTabIds.findIndex(i => i === true);

            if (this.activeTabIds[0]) {
                this.showSlice = false;
                this.showWorksheet = true;
                setTimeout(() => {
                    this.$refs.myTable && this.$refs.myTable.hotInstance && this.$refs.myTable.hotInstance.render();
                }, 100);
            } else {
                this.showWorksheet = false;
                setTimeout(() => {
                    this.$refs.sliceTable &&
                    this.$refs.sliceTable.hotInstance &&
                    this.$refs.sliceTable.hotInstance.updateSettings({
                        data: this.transformSliceData[this.activeTab],
                        mergeCells: this.mergeCellsData[this.activeTab],
                        language: 'en-US'
                    });
                    this.$refs.sliceTable &&  this.$refs.sliceTable.hotInstance && this.$refs.sliceTable.hotInstance.render();
                }, 150);
                this.showSlice = true;
            }
        },

        async downloadSlice() {
            const sliceData = this.tabs.find(item => item.id === this.activeTab);

            this.$emit('loadingChange', true);
            try {
                await downloadSlice(this.aggregateId, sliceData.worksheet, sliceData.type, sliceData.number);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        }
    },

    computed: {
        distributionId() {
            return this.$route.params.distributionId;
        },

        formDistributionId() {
            return this.$route.params.formDistributionId;
        },

        worksheetId() {
            return this.$route.params.worksheetId;
        },

        templateId() {
            return this.$route.params.templateId;
        },

        aggregateId() {
            return this.$route.params.aggregateId;
        },

        canAggregate() {
            return [ formReadOwn, formVerificationRules ].some(p => this.userPermissionsObject[p]) && !this.aggregateId;
        },

        maxColNumber() {
            return (this.dataFromTable[0] && this.dataFromTable[0].length);
        },

        topBarMenuItemsComputed() {
            return [
                {
                    label: 'Дополнительно',
                    icon: 'pi empty',
                    items: [
                        {
                            label: 'Полная актуализация данных',
                            icon: 'pi pi-fw pi-check-circle',
                            command: () => {
                                this.updateCalculations('full');
                                if (Number(process.env.VUE_APP_AUTOCALC_TIMEOUT) > 0) {
                                    this.startTimer(Number(process.env.VUE_APP_AUTOCALC_TIMEOUT));
                                }
                            },
                            disabled: this.disabledCalc,
                            visible: process.env.VUE_APP_AUTOCALC === 'true' && !this.aggregateId && this.currentUser.role.id === 'superAdmin'
                        },
                        {
                            label: 'Актуализировать данные',
                            icon: 'pi pi-fw pi-check-circle',
                            command: () => {
                                this.updateCalculations();
                            },
                        },
                        {
                            label: 'Актуализировать все раздачи',
                            icon: 'pi pi-fw pi-check-circle',
                            command: () => {
                                this.updateAggregate();
                            },
                            visible: !this.distributionId && this.currentUser.role.id === 'superAdmin',
                        },
                        {
                            label: 'Очистка раздачи',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.reset();
                            },
                            visible: !this.aggregateId && this.currentUser.role.id === 'superAdmin',
                        },
                        {
                            label: 'Перейти в свод',
                            icon: 'pi icon custom-summary',
                            command: () => {
                                this.createAggregate(this.distributionId);
                            },
                            visible: this.canAggregate
                        },
                        {
                            label: 'Перейти в заполнение',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                let routeData;
                                if (this.worksheetId) {
                                    routeData = this.$router.resolve({
                                        path: `/dataEntry/${this.formDistributionId}/${this.worksheetId}/`,
                                    });
                                } else if (this.currentWorksheetId) {
                                    routeData = this.$router.resolve({
                                        path: `/dataEntry/${this.formDistributionId}/${this.currentWorksheetId}/`,
                                    });
                                } else {
                                    routeData = this.$router.resolve({
                                        path: `/dataEntry/${this.formDistributionId}/`,
                                    });
                                }
                                window.open(routeData.href, '_blank');
                            },
                            visible: !this.distributionId,
                            disabled: !this.formDistributionId
                        },
                        {
                            label: 'Скачать отчёт',
                            icon: 'pi pi-fw pi-download',
                            items: this.reportTemplates?.map((report) => {
                                return {
                                    label: report.name,
                                    icon: report.type === 'xlsx' ? 'pi icon custom-xls-file' : 'pi icon custom-doc-file',
                                    command: () => {
                                        this.downloadReport(report.id);
                                    }
                                };
                            }),
                            visible: this.reportTemplates && this.reportTemplates.length > 0,
                            disabled: !this.canReport
                        },
                        {
                            label: 'Загрузить данные из файла',
                            icon: 'pi pi-fw pi-upload',
                            command: () => {
                                this.showLoadDataDialog = true;
                            },
                            visible: !this.aggregateId && this.canLoad
                        },
                        {
                            label: 'Скачать XLS',
                            icon: 'pi icon custom-xls-file',
                            command: () => {
                                this.aggregateId ? this.startAggregateUnloading(this.aggregateId, XSL_TEMPLATE_ID) : this.startUnloading(this.distributionId, XSL_TEMPLATE_ID);
                            },
                            visible: this.canReport
                        },
                        {
                            label: 'Скачать массивы XLS',
                            icon: 'pi icon custom-xls-file',
                            command: () => {
                                this.aggregateId ? this.startAggregateUnloading(this.aggregateId, XSLA_TEMPLATE_ID) : this.startUnloading(this.distributionId, XSLA_TEMPLATE_ID);
                            },
                            visible: !this.distributionId && this.canReport
                        },
                        {
                            separator: true,
                        },
                        {
                            label: 'Отключить фиксацию шапки',
                            icon: 'icon custom-unlock',
                            command: () => {
                                this.unlockHeader = true;
                                this.fixHeaders(true, 0, 0);
                            },
                            visible: !!this.headerFixed,
                        }
                    ],
                },
            ];
        },

        ...mapGetters('auth', [
            'currentUser',
            'socketToken',
            'formPermissionsObject',
            'userPermissionsObject'
        ]),
        ...mapGetters(['rowsPerPageOptions']),
    },

    watch: {
        selectedPanel(next) {
            if (!next) {
                this.hideRightPanel();
                return;
            }
            this.showRightPanel();
        },

        formDistributionCheckData: {
            handler: function (val) {
                if (val !== null && this.loadErrorsState && val.items.worksheets.length === 0) {
                    this.loadErrors();
                }

                if (val !== null && val.items.worksheets[0] === this.currentWorksheetId) {
                    this.checkErrors();
                }
            },
            deep: true
        },
    },

    created() {
        if (this.distributionId) {
            const channel = `form-distribution:${this.distributionId}`;
            const channelCheckFormDistribution = `check-form-distribution:${this.distributionId}`;

            this.initConnection(this.socketToken, channel);
            this.initConnection(this.socketToken, channelCheckFormDistribution);
        }
        if (this.aggregateId) {
            const channelAggregateCheck = `check-aggregate:${this.aggregateId}`
            this.initConnection(this.socketToken, channelAggregateCheck);
            this.initConnection(this.socketToken, `refresh-aggregate:${this.aggregateId}`);
        }

        this.dataFromTable = mockDataTableDataArray;
        this.dataFromSlice = mockDataTableDataArray;

        /** Кнопки "Утвердить" и "Отменить утверждение"
         *  доступны только пользователю с правом formApprove
         **/
        if ([ formApprove ].some(p => this.userPermissionsObject[p])) {
            this.canApprove = !this.distributionId;
        }

        /** Открываем правую панель Комментарии по-умолчанию, если есть get-параметр в url */
        if (this.$route.query.comments === null) {
            this.showRightMenu = true;
            this.selectedPanel = this.rightPanels.filter((mode) => mode.code === 'comments')[0];

            setTimeout(() => {
                this.$refs.myTable.hotInstance.render();
            }, 100);
        }
    },

    async mounted() {
        if (this.distributionId) {
            await this.getDistributionData();
        }
        await this.getTemplateData();
        this.updateData();

        this.$root.$on('render-table', () => {
            setTimeout(() => {
                this.$refs.myTable?.hotInstance.render();
            }, 1000);
        });
    },

};
// 2367
</script>

<style lang="scss" scoped>

.hideElem {
    display: none !important;
}

.menuShow {
    opacity: 1;
    min-height: 100px;
    width: 280px;
    max-height: calc(100vh - 148px);
    margin-left: 20px;
}

.customFontSize {
    font-size: 18px;
}

.p-button-yellow,
.p-button-yellow:hover {
    background: #ffe6A5;
    border-color: #ffe6A5;
    color: #495058;
}

.saveButtonStyles {
    padding: 10px;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.card {
    padding: 0;
    height: 100%;
    overflow: hidden;
    box-shadow: none;
    border-radius: 0;
}

.p-col-custom {
    width: calc(100% - 298px);
}

.p-col-customPad {
    background-color: #fff;
    padding: 0 16px !important;
    border-radius: 3px;
}

.p-inputgroup-addon {
    background-color: inherit;
    width: 45px;
    padding: 10px 0;
    border-left: none;
    border-top: none;
    border-bottom: none;
}

.p-inputgroup-addon:last-child {
    padding-right: 15px;
}

.bottomBorder {
    border-bottom: 1px solid #eaeaea;
}

.customRightBorder {
    border-right: 1px solid #eaeaea !important;
}

.p-inputtextarea-resizable {
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
}

.p-inputtext:enabled:hover {
    border-color: #eaeaea;
}

.showLayerButton {
    cursor: pointer;
}

.customRed {
    color: #f44336;
}

.customRedAlert {
    background-color: #f44336;
    padding: 2px 8px;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    i {
        margin-left: 4px;
    }
}

.customRedCircle {
    color: #f44336;
    display: flex;
    align-items: center;
}

.customHeight {
    height: 43px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 4px;
}

.layerContainer {
    position: relative;
    padding-left: 30px;

    .p-button {
        transition: none;
    }

    .p-button.p-button-text:enabled:hover {
        background-color: transparent;
    }

    .p-button:enabled:focus {
        box-shadow: none;
    }
}

.layersOpenButton {
    position: absolute;
    left: 0;
    top: 5px;
    animation: all 1s ease;
}

.layersContainerOpen {
    transform: rotate(90deg);
}

::v-deep .ScrollPanelCustomSettings {
    position: relative;

    .p-scrollpanel-bar {
        background-color: rgba(51, 51, 51, 0.6);
        opacity: 1;
        border-radius: 10px;
        width: 4px;
        right: 8px !important;
        position: absolute;
    }

    .p-scrollpanel-bar-y {
        display: none;
    }

    .p-scrollpanel-bar {
        display: none;
    }

    .p-scrollpanel-content {
        overflow: hidden;
        padding-right: 0;

        div:last-child {
            .p-inputgroup {
                border-bottom: 1px solid #e4e4e4;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    .p-inputgroup {
        width: calc(100% - 2px);
    }

    .customBorder {
        border-left: 1px solid #e4e4e4;
        border-top: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
    }

    .customRightMargin {
        margin-right: 10px;
    }

    .customLayerHover:hover .p-inputgroup,
    .customLayerHover:hover .p-inputtext {
        background-color: #f5f5f5;
    }

    .p-inputgroup {
        width: 100%;
        border-top: 1px solid #e4e4e4;
        border-left: 1px solid #e4e4e4;

        .p-inputtext {
            padding: 9.5px 8px;
        }
    }

    div:nth-child(2) {
        .p-inputgroup {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }
    }

    .p-inputtext,
    .p-inputdiv {
        border-left: 1px solid #e4e4e4;
        border-right: 1px solid #e4e4e4;
        border-top: 0;
        border-bottom: 0;
    }

    .p-inputdiv {
        width: 90%;
        padding: 10px 0.5rem 0.2rem;
        min-height: 43px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        line-height: 1.5rem;
        overflow-wrap: break-word;
    }

    .p-inputdiv:focus-visible {
        outline: none;
    }

    .displayBlock {
        display: block !important;
    }

    .formulaEditor {
        min-height: 35px;
        padding: 9.5px 8px;
    }

    .cellMarker {
        max-height: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #495058;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block !important;
        background: rgba(56, 142, 60, 0.25);
        padding: 2px;
        margin: 1px;
    }
}

::v-deep .openContainer {
    height: 130px;
    overflow-y: auto;

    .ScrollPanelCustomSettings {
        .p-scrollpanel-content {
            overflow-y: auto !important;
        }

        .p-scrollpanel-bar-y {
            display: block;
        }
    }
}

::v-deep .corectPading {
    .p-scrollpanel-content {
        width: 100%;
        height: 100%;
        padding: 0;
    }
}

::v-deep .p-menubar {
    background-color: #f5f5f5;
    border: 1px solid #e4e4e4;
    padding: 8px;
    border-radius: 3px;

    .empty {
        width: 0 !important;
    }

    .p-menuitem-link .p-menuitem-icon {
        margin-right: 1rem !important;
        width: 14px;
    }
    .p-menuitem-link .p-menuitem-icon.custom-summary {
        margin-left: -4px;
        margin-right: 18px !important;
    }

    .p-menuitem-link:focus {
        box-shadow: none !important;
    }

    .p-menubar-root-list {
        .p-menuitem {
            padding: 0;
            margin-right: 8px;

            .p-menuitem-link {
                padding: 9px 7px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #495058;
                display: flex;
                justify-content: flex-start;
            }

            .p-menuitem-link:hover {
                background: #e0e0e0 !important;
            }
        }

        .p-menuitem-active {
            background: #e0e0e0 !important;

            border-radius: 3px;
        }

        .p-menuitem-active > a {
            background: #e0e0e0 !important;
        }

        li {
            .p-submenu-list {
                .p-menuitem {
                    .p-menuitem-link {
                        padding: 9px 20px 9px 15px !important;
                    }
                }
            }
        }

        li:nth-child(3) {
            .ul:nth-child(1) {
                li:nth-child(2) .p-menuitem-link {
                    .p-menuitem-icon {
                        margin-left: -3px;
                    }
                }
            }

            .p-submenu-list {
                li:nth-child(1) {
                    .p-submenu-list {
                        .p-menuitem {
                            .p-menuitem-link {
                                padding: 9px 20px 9px 40px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .p-submenu-list {
        z-index: 200;
        width: 260px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        padding: 4px 0;

        .p-menuitem {
            margin-right: 0;

            .p-menuitem-link {
                padding: 9px 20px 9px 15px;
            }

            .p-menuitem-link:not(.p-disabled):hover {
                background: #f5f5f5 !important;
            }
        }

        .p-menu-separator {
            margin: 1px 0 1px 40px;
        }

        .p-menuitem-active {
            background: #fff !important;
        }

        .p-menuitem-active > a {
            background: #fff !important;
        }
    }

    .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        box-shadow: none;
    }
}
::v-deep .p-menubar-root-list {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}
::v-deep .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    height: 38px;
}

.title-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    background: #e4e4e4;
    border-radius: 4px;
    padding: 6px;
    margin-right: 8px;
    white-space: nowrap;
    margin-bottom: 8px;

    i {
        margin-right: 4px;
    }
}
::v-deep .p-menubar-start {
    display: flex !important;
}
::v-deep .p-menubar-start .p-button-label {
    white-space: nowrap !important;
}

::v-deep .p-menubar-end {

    .pi {
        margin-right: 4px;
    }

    .p-button {
        background: #f5f5f5;
        border: none;
        padding-right: 0;
        padding-left: 12px;

        .p-button-label {
            white-space: nowrap;

            @media screen and (max-width: 1750px) {
                display: none;
            }
        }

        .menu-button {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 18px;
        }

        .icon:hover {
            color: #388e3c;
        }
    }
    .p-button:hover {
        background-color: #f5f5f5 !important;

        .p-button-label {
            color: #388e3c;
        }

        .icon {
            color: #388e3c;
        }
    }

    .p-selectbutton .p-button.p-highlight {
        color: #388e3c;
    }
}

.icon.iconFontSize {
    font-size: 18px;
}

.constTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #272727;
}

.cellMarkerStyles {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
    color: #495058;
}

.customWeight {
    font-weight: 700 !important;
    text-decoration: none;
}

::v-deep .p-dialog {
    .p-dialog-header {
        padding: 24px;

        .p-dialog-header-icon:focus {
            box-shadow: none;
        }
    }
    .p-dialog-content {
        padding: 0 24px;

        label {
            font-size: 13px;
            line-height: 16px;
            color: #272727;
            margin-bottom: 4px;
            display: block;
        }

        .p-field {
            margin-bottom: 0;
        }

        .errorPos {
            position: relative;
        }

        .errorPosition {
            position: absolute;
            left: 0;
            bottom: 4px;
        }

        .message {
            margin-bottom: 16px;
        }
    }

    .p-inputtext,
    .p-dropdown {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }

    .p-dropdown {
        .p-inputtext {
            margin-bottom: 0;
        }
    }

    .p-dialog-footer {
        padding: 0 24px 24px 24px;

        button {
            margin: 0 12px 0 0;
        }
    }

    .p-pr8 {
        padding-right: 8px;
    }

    .p-pl8 {
        padding-left: 8px;
    }
}

::v-deep .layersDialog {
    width: 56.875vw !important;
    max-width: 728px !important;

    table {
        border-collapse: separate;
        border-spacing: 0 0;
    }

    .p-datatable {
        margin-bottom: 16px;
    }

    .p-datatable-header {
        padding: 0 !important;
        border: none;
        position: relative;

        .cellinformation {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #495058;
            margin-left: 16px;
        }

        .table-header {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #495058;
            bottom: -28px;
            left: 41px;
            position: absolute;
        }
    }
    .p-datatable-thead {
        height: 37px !important;

        tr {
            th {
                background: #ffffff !important;
                border: none;
            }
        }
    }

    .p-datatable-tbody {
        margin-top: 37px !important;

        tr:first-child td:first-child {
            border-top-left-radius: 3px !important;
        }

        tr:first-child td:last-child {
            border-top-right-radius: 3px;
        }

        tr:last-child td:first-child {
            border-bottom-left-radius: 3px;
        }

        tr:last-child td:last-child {
            border-bottom-right-radius: 3px;
        }

        tr:last-child td {
            border-bottom: 1px solid #e4e4e4;
        }

        tr {
            height: 37px !important;

            td {
                border-top: 1px solid #e4e4e4;
                border-bottom: none;
            }

            td:first-child {
                border-left: 1px solid #e4e4e4;
            }

            td:nth-child(2) {
                position: relative;
                border-right: 1px solid #e4e4e4;
            }

            td:last-child {
                border-right: 1px solid #e4e4e4;
            }

            .imgFrame {
                position: absolute;
                top: calc(50% - 10px);
                left: 0;
            }
        }
    }
}

::v-deep .customDialogSettings {
    width: 600px !important;

    .p-dialog-content {
        overflow-y: visible;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;

        label {
            margin-bottom: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #495057;
        }
    }

    .p-dialog-footer {
        padding: 0 14px 20px;
    }
}

::v-deep .escapeDialog {
    width: 780px !important;

    .p-dialog-header {
        padding: 24px 0 0 0;
    }

    .p-dialog-content {
        padding: 0 12px 0;
    }

    .p-dialog-footer {
        padding: 24px 12px 24px;
        display: flex;
        justify-content: center;
    }
}

.layout-wrapper.layout-static-inactive .layout-notification {
    margin-left: 0;
}

.layout-notification {
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 1rem 3rem;

    &-wrapper {
        margin-bottom: 8px;
    }

    &-content {
        justify-content: center;
        align-items: center;
        display: flex;
        color: #fff;
        flex: 1;
    }

    &-close {
        line-height: 1.5;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        transition: background-color .3s;
    }

    &-close:hover {
        background-color: hsla(0,0%,100%,.2);
    }
}

::v-deep .p-scrollpanel.custom-bar {
    .p-scrollpanel-content {
        overflow-y: auto;
        overflow-x: hidden;
        width: calc(100% + 15px);
        height: calc(100% + 15px);
        padding: 0;
    }

    .p-scrollpanel-wrapper {
        border-right: 4px solid var(--surface-ground);
    }

    .p-scrollpanel-bar {
        width: 4px;
        background-color: rgb(193, 193, 193);
        opacity: 1;
        transition: background-color .2s;
        min-height: 25px;

        &:hover {
            background-color: rgb(193, 193, 193);
        }
    }

    .p-scrollpanel-hidden {
        opacity: 0;
        min-height: 0 !important;
    }

    .p-scrollpanel-bar-x {
        opacity: 0;
        min-height: 0;
    }
}

::v-deep .p-message {
    display: flex;
    margin: 0 0 1rem;

    .p-message-icon {
        display: none;
    }

    .p-message-text {
        width: 98%;
    }

    .p-message-wrapper {
        align-items: normal;
        position: relative;
        width: 100%;
    }

    .p-message-close {
        width: 28px;
        position: absolute;
        top: 8px;
        right: 8px;
    }
}

.minusMargin {
    margin: -20px -16px;
    background-color: #eaeaea;
}

.minusMargin2 {
    background-color: #eaeaea;
}

.status {
    color: #8794a3;
    white-space: nowrap;

    .status-label {
        background: #ffe6a5;
        padding: 6px;
        border-radius: 4px;
        color: #495058;
    }
}

.form-label {
    background:#e4e4e4;
    color: #495058;
    padding: 6px;
    border-radius: 4px;
    margin-right: 8px;
    white-space: nowrap;
    line-height: 36px;
}

.elementMargin {
    margin-top: 12px;
}

::v-deep .handsontable .ht_master .wtHolder {
    margin: 0 !important;
    padding: 0 !important;
}

::v-deep {
    .htContextMenu table tbody tr td .menuItem .htItemWrapper i,
    .htDropdownMenu table tbody tr td .menuItem .htItemWrapper i {
        margin-right: 8px;
    }

    .htContextMenu table tbody tr td .menuItem,
    .htDropdownMenu table tbody tr td .menuItem {
        margin-left: -30px;
    }
}

::v-deep {
    label.custom-radio input {
        display: none;
    }

    label.custom-radio input + div {
        content: "\a";
        background: #ffffff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: black;
        border: 2px solid #EAEAEA;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .radio-icon {
        width: 12px;
        height: 12px;
        transition-duration: 0.2s;
        background-color: #ffffff;
        border-radius: 50%;
    }

    label.custom-radio input:checked + div {
        background: #388E3C;
        border-color: #2d7230;
        color: #ffffff;
    }

    label.custom-radio.decline input:checked + div {
        background: #FF6767;
        border-color: #FF6767;
        color: #ffffff;
    }

    .p-inputtextarea {
        margin: 4px 0;
    }
}
::v-deep .blocked {
    background: #c9deca6e !important;
}

::v-deep .yellow {
    background: #ffe6A5 !important;
}

::v-deep .error-cell {
    box-shadow: inset 0 0 0 1px rgb(255, 103, 103);
}

.calculated {
    background: #ff6767;
}

.fill-tabs {
    margin-top: 8px;
    margin-bottom: -38px;
    margin-right: 124px;

    &-title {
        z-index: 50;
    }
    ::v-deep {
        .p-tabview {
            margin-top: 8px;

            &-nav-link {
                display: flex;
                justify-content: center;
                padding: 0 0 10px 0 !important;
                width: 5.5vw;
                box-shadow: unset !important;
                span {
                    font-weight: 400 !important;
                }
            }
        }

        .p-tabview-nav {
            border: none !important;
            margin-top: 8px;
            margin-bottom: -4px;
        }
    }
}
::v-deep .constTitle .menu-button {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    color: black;
    overflow: hidden;
    border-radius: 4px;
    background: #f8f9fa;
    transition: background-color 0.2s, box-shadow 0.2s;
    display: block;
    cursor: pointer;
    outline: 0 none;

    &:hover {
        background: #e9ecef;
    }

    .pi-file:before {
        content: "\e958";
        font-size: 16px;
    }
}
::v-deep .menu-button {
    .pi-exclamation-circle:before,
    .pi-exclamation-triangle:before {
        font-size: 17px;
    }
}

::v-deep .header {
    background-color: #dcdcdc;
    color: black;
}
::v-deep .blocked-header.header {
    background-color: #eaeaea;
}
::v-deep .bold-style {
    font-weight: bold;
}

::v-deep .italic-style {
    font-style: italic;
}

::v-deep .strikethrough-style {
    text-decoration: line-through;
}

::v-deep .underline-style {
    text-decoration: underline;
}

::v-deep .burgundy-style {
    color: #980000;
}

::v-deep .red-style {
    color: #ff0000;
}

::v-deep .orange-style {
    color: #ff9900;
}

::v-deep .orange-style {
    color: #ff9900;
}

::v-deep .yellow-style {
    color: #ffff00;
}

::v-deep .green-style {
    color: #00ff00;
}

::v-deep .green-style {
    color: #00ff00;
}

::v-deep .turquoise-style {
    color: #00ffff;
}

::v-deep .blue-style {
    color: #4a86e8;
}

::v-deep .darkblue-style {
    color: #0000ff;
}

::v-deep .violet-style {
    color: #9900ff;
}

::v-deep .purple-style {
    color: #ff00ff;
}

::v-deep .burgundy-background-style {
    background-color: #dd7e6b;
}

::v-deep .red-background-style {
    background-color: #ea9999;
}

::v-deep .orange-background-style {
    background-color: #f9cb9c;
}

::v-deep .yellow-background-style {
    background-color: #ffe599;
}

::v-deep .green-background-style {
    background-color: #b6d7a8;
}

::v-deep .turquoise-background-style {
    background-color: #a2c4c9;
}

::v-deep .blue-background-style {
    background-color: #a4c2f4;
}

::v-deep .darkblue-background-style {
    background-color: #9fc5e8;
}

::v-deep .violet-background-style {
    background-color: #b4a7d6;
}

::v-deep .purple-background-style {
    background-color: #d5a6bd;
}

::v-deep .header .burgundy-background-style {
    background-color: #cc4125;
}

::v-deep .header .red-background-style {
    background-color: #e06666;
}

::v-deep .header .orange-background-style {
    background-color: #f6b26b;
}

::v-deep .header .yellow-background-style {
    background-color: #ffd966;
}

::v-deep .header .green-background-style {
    background-color: #93c47d;
}

::v-deep .header .turquoise-background-style {
    background-color: #76a5af;
}

::v-deep .header .blue-background-style {
    background-color: #6d9eeb;
}

::v-deep .header .darkblue-background-style {
    background-color: #6fa8dc;
}

::v-deep .header .violet-background-style {
    background-color: #8e7cc3;
}

::v-deep .header .purple-background-style {
    background-color: #c27ba0;
}

::v-deep .header .blocked-header .burgundy-background-style {
    background-color: #e6b8af;
}

::v-deep .header .blocked-header .red-background-style {
    background-color: #f4cccc;
}

::v-deep .header .blocked-header .orange-background-style {
    background-color: #fce5cd;
}

::v-deep .header .blocked-header .yellow-background-style {
    background-color: #fff2cc;
}

::v-deep .header .blocked-header .green-background-style {
    background-color: #d9ead3;
}

::v-deep .header .blocked-header .turquoise-background-style {
    background-color: #d0e0e3;
}

::v-deep .header .blocked-header .blue-background-style {
    background-color: #c9daf8;
}

::v-deep .header .blocked-header .darkblue-background-style {
    background-color: #cfe2f3;
}

::v-deep .header .blocked-header .violet-background-style {
    background-color: #d9d2e9;
}

::v-deep .header .blocked-header .purple-background-style {
    background-color: #ead1dc;
}

::v-deep .style-10px {
    font-size: 10px;
}

::v-deep .style-12px {
    font-size: 12px;
}

::v-deep .style-14px {
    font-size: 14px;
}

::v-deep .style-16px {
    font-size: 16px;
}

::v-deep .style-18px {
    font-size: 18px;
}

::v-deep .style-20px {
    font-size: 20px;
}

::v-deep .style-22px {
    font-size: 22px;
}

::v-deep .style-24px {
    font-size: 24px;
}
</style>
